<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: basicCustomerDetails.vue
Description:This file contains the basic details of the customer or dealer or manager.
-->
<template>
  <el-card class="basic-customer-details-card">
    <el-row type="flex" justify="space-between">
      <template v-if="showDeviceCode">
        <div>
          <slot name="deviceCode">
            <el-row class="details__heading">Code</el-row>
            <el-row class="details__heading__value">
              <slot name="deviceCode.value">
                {{ deviceCode }}
              </slot>
            </el-row>
          </slot>
        </div>
      </template>

      <template v-if="showDealerDetails">
        <slot name="customer-details">
          <div>
            <el-row class="details__heading">Dealer Name</el-row>
            <el-row class="details__heading__value">{{ customerName }}</el-row>
          </div>
          <div>
            <el-row class="details__heading">Email Address</el-row>
            <el-row class="details__heading__value__without_capitalize">{{
              email
            }}</el-row>
          </div>

          <div>
            <el-row class="details__heading">Mobile Number</el-row>
            <el-row class="details__heading__value">{{ phoneNumber }}</el-row>
          </div>
        </slot>
      </template>

      <template v-if="showCustomerDetails">
        <slot name="customer-details">
          <div>
            <el-row class="details__heading">Customer Name</el-row>
            <el-row class="details__heading__value">{{ customerName }}</el-row>
          </div>

          <div>
            <el-row class="details__heading">Email Address</el-row>
            <el-row class="details__heading__value__without_capitalize">{{
              email
            }}</el-row>
          </div>

          <div>
            <el-row class="details__heading">Mobile Number</el-row>
            <el-row class="details__heading__value">{{ phoneNumber }}</el-row>
          </div>
        </slot>
      </template>

      <template v-if="showDeviceCode">
        <div>
          <el-row class="details__heading">Last Comm Time</el-row>
          <el-row class="details__heading__value">{{
            ftm__formatEmpty(adm__filterToFormatDate(lastCommunicatedAt))
          }}</el-row>
        </div>
      </template>
      <slot name="moreDetails"></slot>
      <layout-toolbar class="actions-container">
        <slot name="moreActions"></slot>
        <template v-if="allowAddCustomer">
          <el-switch
            v-model="bscEditable"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="Edit"
            @change="handleFormEdit"
            v-if="userPermissionCheck"
          >
          </el-switch>
          <!-- <er-button size="mini" type="primary">Edit</er-button> -->
        </template>
        <!-- <template v-if="allowCustomerLogin">
          <er-button
            type="primary"
            size="mini"
            :disabled="typeof userDetails === 'undefined'"
            @click="handleCustomerClick({ id: user._id, name: fullName(user) })"
            >Login as User
          </er-button>
        </template> -->
      </layout-toolbar>
    </el-row>
  </el-card>
</template>

<script>
import User from "@/model/user";
import filtersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import { mapActions } from "vuex";
export default {
  mixins: [filtersMixin, adminDatesHandlerMixin],
  props: {
    deviceDetails: {
      default: () => {}
    },
    userDetails: {
      default: () => {}
    },
    showDealerDetails: {
      default: false
    },
    showCustomerDetails: {
      default: false
    },
    showDeviceCode: { default: true },
    allowAddCustomer: { default: true },
    allowCustomerLogin: { default: true }
  },
  data: function() {
    return {
      emptyStr: "--",
      bscEditable: false
    };
  },
  computed: {
    allowCustomerTab() {
      return this.$store.getters["superadmin/getSuperAdminProfile"]?.permissions || [];
    },
    userPermissionCheck() {
      return this.allowCustomerTab.includes("UPDATE_DEVICE_SETTINGS_ALL");
    },
    deviceCode() {
      return this.computedDeviceDetails.code;
    },
    computedDeviceDetails() {
      return this.deviceDetails || { code: this.emptyStr };
    },
    user: function() {
      if (this.userDetails) {
        return this.userDetails;
      } else {
        return new User();
      }
    },
    fullName() {
      return function(user) {
        return `${user.first_name} ${user.last_name}`;
      };
    },
    customerName() {
      return this.user.first_name && this.user.last_name
        ? `${this.user.first_name} ${this.user.last_name}`
        : this.user.first_name
        ? this.user.first_name
        : this.user.last_name
        ? this.user.last_name
        : "--";
    },
    email() {
      return this.user.email ? this.user.email.toLowerCase() : "--";
    },
    phoneNumber() {
      return this.user.phone || this.emptyStr;
    },
    lastCommunicatedAt() {
      if (this.deviceDetails && this.deviceDetails.utc_last_communicated_at) {
        return this.deviceDetails.utc_last_communicated_at;
      } else if (
        this.deviceDetails &&
        this.deviceDetails.last_communicated_at
      ) {
        return this.deviceDetails.last_communicated_at;
      }
      return undefined;
    }
  },
  methods: {
    ...mapActions("superadmin", {
      loginAsCustomer: "loginAsCustomer"
    }),
    handleFormEdit() {
      this.$emit("change-edit", !this.bscEditable);
    },
    async handleCustomerClick(command) {
      if (command) {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading"
        });
        await this.loginAsCustomer(command.id);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("login-in-as-name", { name: command.name }),
          duration: 5000,
          type: "success"
        });
        this.$router.push(
          "/user",
          (...args) => {
            loading.close();
            console.log(args);
          },
          (...args) => {
            loading.close();
            console.log(args);
          }
        );
      }
    }
  }
};
</script>

<style lang="scss">
.basic-customer-details-card {
  line-height: 0.9;
  margin-left: 10px;
  margin-right: 10px;
  .details__heading {
    @include responsiveProperty(
      font-size,
      $app_font_size_v_medium,
      $app_font_size_small,
      $app_font_size_1
    );
    font-weight: 500;
    margin-bottom: 8px;
  }
  .el-card__body {
    padding: 10px;
  }
  .details__heading__value {
    text-transform: capitalize;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );

    color: #444444;
  }
  .details__heading__value__without_capitalize {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
    color: #444444;
  }
  .actions-container {
    align-self: center;
  }

  .el-input--mini {
    width: 100px;
  }
}
</style>
