var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-AccountCustomers"},[_c('er-dialog',{staticClass:"action-on-dealer",attrs:{"width":"30%","visible":_vm.dialogVisible,"append-to-body":true,"title":_vm.dailogTitle,"custom-class":"Add_Users_To_Account_Manager"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"open":_vm.handleOpenDialog}},[_c('ValidationObserver',{ref:"dialogListForm"},[_c('el-form',{attrs:{"size":"large"}},[(_vm.dailogTitle === 'Replace Executive')?_c('ValidationProvider',{attrs:{"name":"Exixsting Sales Executive"}},[_c('el-form-item',[_c('er-input',{attrs:{"disabled":"","value":_vm.existingExecutive.first_name +
                    '\xa0\xa0' +
                    _vm.existingExecutive.last_name + '(' + _vm.existingExecutive.email + ')'}})],1)],1):_vm._e(),_vm._v(" "),(_vm.dailogTitle === 'Replace Executive')?_c('ValidationProvider',{attrs:{"name":"User Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-form-item',{attrs:{"error":errors[0]}},[_c('er-select',{staticClass:"select-devices",attrs:{"placeholder":_vm.$t('Comn_search'),"loading":_vm.userListLoading,"value-key":"_id","collapse-tags":"","filterable":"","remote":"","remote-method":_vm.fetchUsersToAssign},model:{value:(_vm.selectedExecutive),callback:function ($$v) {_vm.selectedExecutive=$$v},expression:"selectedExecutive"}},_vm._l((_vm.usersList),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.first_name +
                    '\xa0\xa0' +
                    item.last_name.concat('(' + item.email + ')'),"value":item}})}),1)],1)]}}],null,false,686823168)}):_c('ValidationProvider',{attrs:{"name":"User Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-form-item',{attrs:{"error":errors[0]}},[_c('er-select',{staticClass:"select-devices",attrs:{"placeholder":_vm.$t('Comn_search'),"loading":_vm.userListLoading,"value-key":"_id","collapse-tags":"","multiple":"","filterable":"","remote":"","remote-method":_vm.fetchUsersToAssign},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}},_vm._l((_vm.usersList),function(item){return _c('el-option',{key:item._id,attrs:{"label":item.first_name +
                    '\xa0\xa0' +
                    item.last_name.concat('(' + item.email + ')'),"value":item}})}),1)],1)]}}])})],1)],1),_vm._v(" "),_c('template',{slot:"footer"},[_c('er-button',{attrs:{"btnType":"save","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.submitUserDetails}}),_vm._v(" "),_c('er-button',{attrs:{"btnType":"cancel","showLabel":true,"loading":_vm.saveLoading},on:{"click":_vm.handleCloseDialog}})],1)],2),_vm._v(" "),_c('settings-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.salesmanager_id,staticClass:"add_PondMother",attrs:{"element-loading-background":"white","tableData":_vm.tableData,"columns":_vm.columnsObject,"total":_vm.total,"button":true,"placeHolderForSearch":"Search_username","columnselector_require":false,"search-property":"user_name","addPadingToSearch":true},on:{"loadData":_vm.handleLoadData,"reload":_vm.handleLoadData,"cleared":_vm.handleLoadData,"add-item-click":_vm.handleAddDealerClick},scopedSlots:_vm._u([{key:"name",fn:function(ref){
                    var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.first_name)+" "+_vm._s(row.data.last_name)+"\n    ")]}},{key:"status",fn:function(ref){
                    var row = ref.row;
return [_vm._v("\n      "+_vm._s(row.data.status === 'ACTIVE' ? "Active" : "Inactive")+"\n    ")]}},{key:"actions",fn:function(ref){
                    var row = ref.row;
return [_c('el-button-group',[_c('er-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleUserDelete(row.data)}}},[_c('span',{staticClass:"material-icons-round"},[_vm._v(" delete ")])]),_vm._v(" "),_c('er-button',{attrs:{"size":"mini","icon":"el-icon-refresh"},on:{"click":function($event){return _vm.handleReplaceExecutiveClick(row.data)}}})],1)]}}])},[_c('template',{slot:"add-buttton"},[_vm._v("Add Executive")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }