<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: addDevicesToUser.vue
Description:This file contains the form where the devices codes(ex :pond mother code) should be filled and added to the customer by the superadmin
-->
<template>
  <er-dialog
    width="30%"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog"
    @open="initComponent"
    :append-to-body="true"
    :title="
      `${$t(action === 'ADD_DEVICES' ? 'Comn_add' : 'Comn_delete')} Devices`
    "
    class="add-devices-to-user"
    custom-class="add-devices-to-user-dialog"
    v-loading="loading"
    element-loading-background="white"
  >
    <ValidationObserver ref="dialogListForm">
      <el-form size="small">
        <el-form-item label="Customer Name">
          <el-col>
            {{
              this.customer_details.first_name +
                " " +
                this.customer_details.last_name
            }}
          </el-col>
        </el-form-item>

        <ValidationProvider
          name="PondGuards"
          v-slot="{ errors }"
          class="form-item-select"
        >
          <el-form-item
            :label="$t('Comn_pond_guard')"
            :error="errors[0]"
          >
            <el-col>
              <er-select
                v-model="selectedPGs"
                :reserve-keyword="true"
                :showSelectAll="true"
                class="select-devices"
                value-key="code"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :loading="sLoading === 'POND_GUARD'"
                :remote-method="fetchPondGuardByCode"
                placeholder="Enter PondGuard Code"
                :no-data-text="$t('Comn_no_pondguards')"
              >
                <el-option
                  v-for="item in pond_guards"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  <span style="white-space: initial">
                    {{ item.code }} ({{ item.v1_code }})</span
                  >
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="PondMothers"
          v-slot="{ errors }"
          class="form-item-select"
        >
          <el-form-item
            :label="$t('Comn_pond_mother')"
            :error="errors[0]"
          >
            <el-col :span="24">
              <er-select
                v-model="selectedPMs"
                :showSelectAll="true"
                :reserve-keyword="true"
                class="select-devices"
                value-key="code"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :remote-method="fetchPondMotherByCode"
                :loading="sLoading === 'POND_MOTHER'"
                placeholder="Enter PondMother Code"
                :no-data-text="$t('Comn_no_pondmothers')"
              >
                <el-option
                  v-for="item in pond_mothers"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  <span style="white-space: initial">
                    {{ item.code }} ({{ item.v1_code }})</span
                  >
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider
          name="Shrimptalk"
          v-slot="{ errors }"
          class="form-item-select"
        >
          <el-form-item
            :label="$t('Comn_shrimptalk')"
            :error="errors[0]"
          >
            <el-col :span="24">
              <er-select
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedSTs"
                value-key="code"
                :reserve-keyword="true"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :remote-method="fetchShrimpTalkByCode"
                :loading="sLoading === 'SHRIMP_TALK'"
                placeholder="Enter ShrimpTalk Code"
                :no-data-text="$t('Comn_no_shrimptalks')"
              >
                <el-option
                  v-for="item in shrimp_talks"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  <span style="white-space: initial">
                    {{ item.code }} ({{ item.v1_code }})</span
                  >
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider name="Gateway" v-slot="{ errors }">
          <el-form-item
            :label="$t('Comn_gateway')"
            :error="errors[0]"
          >
            <el-col :span="24">
              <er-select
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedGWs"
                value-key="code"
                :reserve-keyword="true"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :remote-method="fetchGatewayByCode"
                :loading="sLoading === 'GATEWAY'"
                placeholder="Enter Gateway Code"
                no-data-text="No Gateways"
              >
                <el-option
                  v-for="item in gateways"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  <span style="white-space: initial">
                    {{ item.code }} ({{ item.v1_code }})</span
                  >
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>

        <ValidationProvider name="Feedblower" v-slot="{ errors }">
          <el-form-item
            :label="$t('devices.feed-blower')"
            :error="errors[0]"
          >
            <el-col :span="24">
              <er-select
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedFBs"
                value-key="code"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :reserve-keyword="true"
                :remote-method="fetchFeedBlowerByCode"
                :loading="sLoading === 'FEEDBLOWER'"
                placeholder="Enter Feed Blower Code"
                no-data-text="No Feed Blowers"
              >
                <el-option
                  v-for="item in feed_blowers"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  {{ item.code }} ({{ item.v1_code }})
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>
        <ValidationProvider name="ShrimpSnap" v-slot="{ errors }">
          <el-form-item
            label="ShrimpSnap"
            :error="errors[0]"
          >
            <el-col :span="24">
              <er-select
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedSSs"
                value-key="code"
                collapse-tags
                multiple
                filterable
                :remote="isAddAction"
                :reserve-keyword="true"
                :remote-method="fetchShrimpSnapByCode"
                :loading="sLoading === 'SHRIMP_SNAP'"
                placeholder="Enter ShrimpSnap Code"
                no-data-text="No ShrimpSnap"
              >
                <el-option
                  v-for="item in shrimp_snaps"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                >
                  {{ item.code }} ({{ item.v1_code }})
                </el-option>
              </er-select>
            </el-col>
          </el-form-item>
        </ValidationProvider>
      </el-form>
    </ValidationObserver>
    <layout-toolbar slot="footer" justify="center">
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="submitDeviceDetails"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog"
      ></er-button>
    </layout-toolbar>
  </er-dialog>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin],
  props: {
    customer_details: {
      default: () => ({})
    },
    showDialog: {
      default: false
    },
    action: {
      default: "ADD_DEVICES"
    }
  },
  data: function() {
    return {
      pond_guards: [],
      pond_mothers: [],
      shrimp_talks: [],
      gateways: [],
      feed_blowers: [],
      selectedPMs: [],
      selectedSTs: [],
      selectedPGs: [],
      selectedGWs: [],
      selectedFBs: [],
      query: {
        include: ["title", "code", "v1_code"],
        un_assigned_only: true
      },
      loading: false,
      sLoading: ""
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getGateways: "getGateways",
      getPondMothers: "getPondMothers",
      getPondGuards: "getPondGuards",
      getShrimpTalks: "getShrimpTalks",
      getUnassignedGateways: "getUnassignedGateways",
      getUnassignedPondGuards: "getUnassignedPondGuards",
      getUnassignedPondMothers: "getUnassignedPondMothers",
      getUnassignedShrimpTalks: "getUnassignedShrimpTalks",
      getUnassignedFeedBlowers: "getUnassignedFeedBlowers",
      getUnassignedShrimpSnaps: "getUnassignedShrimpSnaps"
    }),
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    },
    isAddAction() {
      return this.action === "ADD_DEVICES";
    }
  },
  methods: {
    ...mapActions("superadmin", {
      addDevicesToUser: "addDevicesToUser",
      deleteDevicesToUser: "deleteDevicesToUser",
      fetchAUserDetails: "fetchAUserDetails",
      fetchAUserDetails_FeedBlower: "fetchAUserDetails_FeedBlower"
    }),
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
      }
    },
    handleCloseDialog(event) {
      this.selectedSTs = [];
      this.selectedPMs = [];
      this.selectedPGs = [];
      this.selectedGWs = [];
      this.selectedFBs = [];
      this.selectedSSs = [];
      this.pond_guards = [];
      this.pond_mothers = [];
      this.shrimp_talks = [];
      this.gateways = [];
      this.feed_blowers = [];
      this.shrimp_snaps = [];
      this.$emit("close_dialog", event);
    },
    async initComponent() {
      try {
        this.loading = true;
        if (this.action === "ADD_DEVICES") {
          await this.initDialogForAddDevices();
        } else {
          await this.initDialogForDeleteDevices();
        }
        this.$nextTick(() => {
          this.$refs.dialogListForm.reset();
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initDialogForDeleteDevices() {
      const response = await this.fetchAUserDetails({
        user_id: this.customer_details._id,
        params: { include_devices: true }
      });
      const user_feed_blower_response = await this.fetchAUserDetails_FeedBlower(
        {
          user_id: this.customer_details._id
        }
      );
      this.gateways = response.gateways;
      this.pond_guards = response.pond_guards;
      this.pond_mothers = response.pond_mothers;
      this.shrimp_talks = response.shrimp_talks;
      this.feed_blowers = user_feed_blower_response.feed_blowers;
      this.shrimp_snaps = response.shrimp_snaps;
    },
    async initDialogForAddDevices() {
      const pmQueryParams = {
        ...this.query,
        include: ["title", "code", "v1_code", "managed_by"]
      };
      const arrPromises = [
        {
          device_type: "pond_mother",
          query: pmQueryParams
        },
        {
          device_type: "shrimp_talk",
          query: this.query
        },
        {
          device_type: "pond_guard",
          query: this.query
        },
        {
          device_type: "gateway",
          query: this.query
        },
        {
          device_type: "feed_blower",
          query: this.query
        },
        {
          device_type: "shrimp_snap",
          query: this.query
        }
      ].reduce((acc, { device_type, query }) => {
        acc.push(
          this.$store.dispatch("superadmin/fetchAllDeviceDetails", {
            device_type,
            params: query
          })
        );
        return acc;
      }, []);
      for (const promise of arrPromises) {
        try {
          await promise;
        } catch (err) {
          console.error(err);
        }
      }
      this.gateways = this.getUnassignedGateways;
      this.pond_guards = this.getUnassignedPondGuards;
      this.pond_mothers = this.getUnassignedPondMothers;
      this.shrimp_talks = this.getUnassignedShrimpTalks;
      this.feed_blowers = this.getUnassignedFeedBlowers;
      this.shrimp_snaps = this.getUnassignedShrimpSnaps;
    },

    async fetchFeedBlowerByCode(queryString) {
      if (queryString === "") {
        this.feed_blowers = [];
        return;
      }
      this.sLoading = "FEEDBLOWER";
      const fBQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase()
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "feed_blower",
            params: fBQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.feed_blowers = this.getUnassignedFeedBlowers;
        this.sLoading = "";
      }
    },
    async fetchShrimpSnapByCode(queryString) {
      if (queryString === "") {
        this.shrimp_snaps = [];
        return;
      }
      this.sLoading = "SHRIMP_SNAP";
      const SSQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase().includes("SS")
          ? queryString
              .substr(2)
              .trim()
              .toUpperCase()
          : queryString
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "shrimp_snap",
            params: SSQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.shrimp_snaps = this.getUnassignedShrimpSnaps;
        this.sLoading = "";
      }
    },
    async fetchShrimpTalkByCode(queryString) {
      if (queryString === "") {
        this.shrimp_talks = [];
        return;
      }
      this.sLoading = "SHRIMP_TALK";
      const stQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase()
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "shrimp_talk",
            params: stQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.shrimp_talks = this.getUnassignedShrimpTalks;
        this.sLoading = "";
      }
    },
    async fetchPondMotherByCode(queryString) {
      if (queryString === "") {
        this.pond_mothers = [];
        return;
      }
      this.sLoading = "POND_MOTHER";
      const pmQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase(),
        include: ["title", "code", "v1_code", "managed_by"]
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "pond_mother",
            params: pmQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.pond_mothers = this.getUnassignedPondMothers;
        this.sLoading = "";
      }
    },
    async fetchPondGuardByCode(queryString) {
      if (queryString === "") {
        this.pond_guards = [];
        return;
      }
      this.sLoading = "POND_GUARD";
      const pgQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase()
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "pond_guard",
            params: pgQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.pond_guards = this.getUnassignedPondGuards;
        this.sLoading = "";
      }
    },
    async fetchGatewayByCode(queryString) {
      if (queryString === "") {
        this.gateways = [];
        return;
      }
      this.sLoading = "GATEWAY";
      const gatewayQueryParams = {
        ...this.query,
        device_code: queryString.toUpperCase()
      };
      try {
        await this.$store.dispatch(
          "superadmin/fetchAllUnassignedDeviceDetails",
          {
            device_type: "gateway",
            params: gatewayQueryParams
          }
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.gateways = this.getUnassignedGateways;
        this.sLoading = "";
      }
    },
    async submitDeviceDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      this.loading = true;
      const actionToStoreAction = {
        ADD_DEVICES: this.addDevicesToUser,
        DELETE_DEVICES: this.deleteDevicesToUser
      };
      try {
        const promises = [
          { device_type: "POND_MOTHER", payload: this.selectedPMs },
          { device_type: "SHRIMP_TALK", payload: this.selectedSTs },
          { device_type: "POND_GUARD", payload: this.selectedPGs },
          { device_type: "GATEWAY", payload: this.selectedGWs },
          { device_type: "FEED_BLOWER", payload: this.selectedFBs },
          { device_type: "SHRIMP_SNAP", payload: this.selectedSSs }
        ].reduce((acc, item) => {
          const payload = item.payload.map(x => x._id);
          if (payload.length === 0) return acc;
          acc.push(
            actionToStoreAction[this.action]({
              user_id: this.customer_details._id,
              payload,
              device_type: item.device_type
            })
          );
          return acc;
        }, []);
        if (promises.length === 0) {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("DL_no_device_selected")
              }
            ]
          };
        }
        await Promise.all(promises);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message:
            this.action === "DELETE_DEVICES"
              ? this.$t("Comn_remove_devices_user")
              : this.$t("Comn_add_devices_user"),
          duration: 5000,
          type: "success"
        });
        this.handleCloseDialog("deviceAddition");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    ehm__error422Handler(err) {
      const errorMessages = err.response.data.errors.details.map(x => ({
        message: x.message
      }));
      this.ehm__errorFailToSave(errorMessages);
    }
  }
};
</script>

<style lang="scss">
// .add-devices-to-user {
//   .el-dialog__body {
//     padding: 15px 15px;
//   }
//   .el-form {
//     .el-form-item__content {
//       .select-devices {
//         width: 200px;
//         .el-input__inner {
//           text-transform: capitalize;
//         }
//       }
//     }
//   }
// }
// .add-devices-to-user-dialog {
//   background: #fcfcfc;
//   border-radius: 10px;
//   .el-form {
//     display: grid;
//   }
//   .el-form-item.el-form-item--small {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     grid-template-rows: 1fr;
//     @include responsiveProperty(
//       font-size,
//       $app_font_size_1,
//       $app_font_size_2,
//       $app_font_size_3
//     );
//     .el-form-item__label {
//       grid-area: 1 / 1 / 2 / 2;
//     }
//     .el-form-item__content {
//       grid-area: 1 / 2 / 2 / 3;
//       .el-input-number {
//         width: 100%;
//       }
//       .el-date-editor {
//         width: 100%;
//       }
//     }
//   }

// .el-input--small .el-input__inner {
//   @include responsiveProperty(width, 200px, 200px, 200px);
// }

.add-devices-to-user-dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 105px, 160px, 210px);
  }
}
</style>
