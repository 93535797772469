<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: shrimpTalkFeedDetails.vue
Description: This file contains UI components of shrimp Talk Feed Details used in super admin page
-->
<template>
  <el-row :key="selectedUser._id" class="st-feed-details">
    <el-row class="user-details">
      <el-col :span="17">
        {{ selectedUser.first_name }} {{ selectedUser.last_name }}
      </el-col>
      <el-col :span="3">
        <er-single-day-picker
          :value="feedDate"
          value-format="yyyy-MM-dd"
          format="dd-MMM-yy"
          @change-prev-day="prevDayClick"
          @change-next-day="nextDayClick"
          @change-day="handlePresentDateChange"
        ></er-single-day-picker>
      </el-col>
      <el-col :span="4">
        <er-select
          size="mini"
          :value="selectedLocation"
          @input="handleChangeInLocation"
          value-key="_id"
        >
          <el-option
            v-for="(location, key) in listOfAllLocations"
            :key="`location-${key}`"
            :value="location"
            :label="location.name"
            >{{ location.name }}
          </el-option>
        </er-select>
      </el-col>
    </el-row>
    <el-row :key="feedDate">
      <er-data-tables
        ref="shrimpTalkFeedDetails"
        v-loading="loading"
        type="white-header-table-inner"
        layout="table,pagination"
        element-loading-background="white"
        :tableData="shrimpTalkSummary"
        :columns="columnsObject"
        :el-table-props="firsttableProps"
        :sortMethodForColumns="sortMethodForColumns"
        :expand-column="true"
        :action-column="false"
        unique-id="shrimp_talk_feed_table"
        :key="$i18n.locale"
        :page-size="$constants.table['page-size']"
        @expand-change="handleExpandChange"
      >
        <template v-slot:ratio="{ row }">
          {{ getRatio(row.data.dispensed_feed, row.data.ciba_model_feed) }}
        </template>
        <template v-slot:[`feed_limit_(kg)`]="{ row }">
          {{ ftm__formatEmpty(ftm__digitPrecision(row.data.feed, 2)) }}
        </template>
        <template v-slot:[`dispensed_feed_(kg)`]="{ row }">
          {{
            ftm__formatEmpty(ftm__digitPrecision(row.data.dispensed_feed, 2))
          }}
        </template>
        <template v-slot:[`abw_(g)`]="{ row }">
          <p :style="`color: ${getABWColor(row.data.wg)};`">
            {{ ftm__formatEmpty(row.data.abw) }}
          </p>
        </template>

        <template v-slot:expandColumn="{ row }">
          <er-data-tables
            element-loading-background="white"
            v-loading="expandloading === row.data._id"
            :tableData="getInnerRowData(row.data)"
            :columns="expandedTableColumns"
            :el-table-props="tableProps"
            :merge="{ startColumnNumber: 1, endColumnNumber: 2 }"
            :action-column="false"
            :unique-id="`shrimp-talk-feed-inner-table-${row._id}`"
            type="white-header-table-inner"
            layout="table"
          >
            <template v-slot:st_v1_code="{ row }">
              <div>
                <el-tooltip
                  popperClass="admin-st-feed-details-el-tooltip__popper"
                  class="item"
                  effect="light"
                  :content="
                    `Last Communicated: ${row.data.st_last_communicated_at}`
                  "
                  placement="top"
                >
                  <p>{{ row.data.st_v1_code }}</p>
                </el-tooltip>
                <span
                  v-if="!row.data.comm_status"
                  class="dot-small"
                  :style="`background-color: red;`"
                ></span>
              </div>
            </template>
            <template v-slot:pm_title="{ row }">
              <div>
                <el-tooltip
                  popperClass="admin-st-feed-details-el-tooltip__popper"
                  class="item"
                  effect="light"
                  placement="top"
                >
                  <div slot="content">
                    {{ `Last Communicated: ${row.data.pm_last_communicated_at}`
                    }}<br />{{ `Gateway Code: ${row.data.pm_gateway_code}` }}
                  </div>
                  <p
                    :style="
                      `font-weight: ${
                        row.data.pm_master_id === row.data.pm_id
                          ? '900'
                          : 'normal'
                      };`
                    "
                    v-if="row.data.pm_managed_by === 'SHRIMP_TALK'"
                  >
                    {{ row.data.pm_title }}
                  </p>
                  <el-tag v-else type="warning">
                    {{ row.data.pm_title }}</el-tag
                  >
                </el-tooltip>
                <span
                  v-if="!row.data.comm_status"
                  class="dot-small"
                  :style="`background-color: red;`"
                ></span>
              </div>
            </template>
            <template v-slot:pm_ratio="{ row }">
              <p :style="`color: ${getRatioColor(row.data.pm_ratio)};`">
                {{
                  ftm__formatEmpty(ftm__digitPrecision(row.data.pm_ratio, 2))
                }}
              </p>
            </template>
            <template v-slot:st_ratio="{ row }">
              <p :style="`color: ${getRatioColor(row.data.st_ratio)};`">
                {{
                  ftm__formatEmpty(ftm__digitPrecision(row.data.st_ratio, 2))
                }}
              </p>
            </template>
            <template v-slot:st_df="{ row }">
              {{
                ftm__formatEmpty(
                  ftm__digitPrecision(row.data.st_disp_feed_limit, 0)
                )
              }}
              /
              {{
                ftm__formatEmpty(ftm__digitPrecision(row.data.st_feed_limit, 0))
              }}
            </template>
            <template v-slot:pm_df="{ row }">
              {{
                ftm__formatEmpty(
                  ftm__digitPrecision(row.data.pm_disp_feed_limit, 0)
                )
              }}
              /
              {{
                ftm__formatEmpty(ftm__digitPrecision(row.data.pm_feed_limit, 0))
              }}
            </template>
            <template v-slot:latest_intake="{ row }">
              <el-row v-if="row.data.latest_intake !== '-'">
                <span
                  class="dot"
                  :style="
                    `background-color: ${
                      colorCode[row.data.latest_intake].color
                    };`
                  "
                ></span>
              </el-row>
              <el-row v-else>-</el-row>
            </template>
            <template v-slot:intake="{ row }">
              <el-row v-if="row.data.intake !== '-'">
                <div class="bar">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    title="'shrimptalk no intake percent'"
                    :style="
                      `
                  background-color: ${row.data.intake[0].color};
                  height: 20px;
                  width: ${2.5 * Math.round(row.data.intake[0].percent)}px;
                `
                    "
                  >
                    <p style="font-size: 9px">
                      {{
                        ftm__formatEmpty(
                          ftm__digitPrecision(row.data.intake[0].percent, 1)
                        )
                      }}
                    </p>
                  </div>
                  <div
                    class="progress-bar"
                    role="progressbar"
                    title="'shrimptalk poor intake percent'"
                    :style="
                      `
                  background-color: ${row.data.intake[1].color};
                  height: 20px;
                  width: ${2.5 * Math.round(row.data.intake[1].percent)}px;
                `
                    "
                  >
                    <p style="font-size: 9px">
                      {{
                        ftm__formatEmpty(
                          ftm__digitPrecision(row.data.intake[1].percent, 0)
                        )
                      }}
                    </p>
                  </div>
                  <div
                    class="progress-bar"
                    role="progressbar"
                    title="'shrimptalk no intake percent'"
                    :style="
                      `
                  background-color: ${row.data.intake[2].color};
                  height: 20px;
                  width: ${2.5 * Math.round(row.data.intake[2].percent)}px;
                `
                    "
                  >
                    <p style="font-size: 9px">
                      {{
                        ftm__formatEmpty(
                          ftm__digitPrecision(row.data.intake[2].percent, 1)
                        )
                      }}
                    </p>
                  </div>
                  <div
                    class="progress-bar"
                    role="progressbar"
                    title="'shrimptalk noise percent'"
                    :style="
                      `
                  background-color:  ${row.data.intake[3].color};
                  height: 20px;
                  width: ${2.5 * Math.round(row.data.intake[3].percent)}px;
                `
                    "
                  >
                    <p style="font-size: 9px">
                      {{
                        ftm__formatEmpty(
                          ftm__digitPrecision(row.data.intake[3].percent, 1)
                        )
                      }}
                    </p>
                  </div>
                </div>
              </el-row>
              <el-row v-else>-</el-row>
            </template>
          </er-data-tables>
        </template>
      </er-data-tables>
    </el-row>
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SuperAdminService from "@/services/SuperAdminService";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import urlParamsUpdateMixin from "@/mixins/urlParamsUpdateMixin";
export default {
  name: "shrimpTalkFeedDetails",
  props: ["deviceDetails", "customerDetails", "selectedUser"],
  mixins: [
    filtersMixin,
    urlParamsUpdateMixin,
    errorHandlerMixin,
    adminDatesHandlerMixin
  ],

  data: function() {
    return {
      loading: false,
      expandloading: "",
      listOfAllLocations: [],
      innerTableData: {},
      querycopy: {},
      feedDate: "",
      emptyStr: "--",
      // total: 0,
      firsttableProps: {
        defaultSort: {
          prop: "title"
        },
        height: "calc(100vh - var(--table-gutter))"
      },
      tableProps: {
        size: "small",
        rowKey: this.getRowKey
      },
      colorCode: {
        0: {
          key: 0,
          label: "No intake",
          percent: 0,
          color: "red"
        },
        1: {
          key: 1,
          label: "Poor intake",
          percent: 0,
          color: "orange"
        },
        2: {
          key: 2,
          label: "Good intake",
          percent: 0,
          color: "green"
        },
        3: {
          key: 3,
          label: "noise",
          percent: 0,
          color: "black"
        }
      },
      shrimpTalkSummary: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.shrimpTalkFeedDetails) {
        this.$refs.shrimpTalkFeedDetails.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
    this.initDate();
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    sortMethodForColumns: function() {
      return {
        title: this.$commonUtils.alphaNumericComparator
      };
    },

    columnsObject: function() {
      return {
        title: {
          label: this.$t("Comn_pond_name"),
          prop: "title",
          required: true,
          sortable: "custom"
        },
        ciba_model_feed: {
          label: "Feed Limit (CIBA)",
          prop: "ciba_model_feed",
          required: true,
          sortable: true
        },
        feed: {
          label: "Feed Limit (Kg)",
          prop: "feed",
          required: true,
          sortable: true
        },
        dispensed_feed: {
          label: "Dispensed Feed (Kg)",
          prop: "dispensed_feed",
          required: true,
          sortable: true
        },
        ratio: {
          label: "Ratio",
          prop: "ratio",
          required: true,
          sortable: true
        },
        abw: {
          label: "ABW (g)",
          prop: "abw",
          required: true,
          sortable: true
        }
      };
    },
    expandedTableColumns: function() {
      return {
        st_v1_code: { label: "ST ID", prop: "st_v1_code" },
        pm_title: { label: "PM ID", prop: "pm_title" },
        pm_df: { label: "PM DF/LIMIT", prop: "pm_df" },
        pm_ratio: { label: "PM Ratio", prop: "pm_ratio" },
        latest_error_code: {
          label: "Latest Error Code",
          prop: "latest_error_code"
        },
        st_hours_active: { label: "ST Hours Active", prop: "st_hours_active" },
        st_df: { label: "ST DF/LIMIT", prop: "st_df" },
        st_ratio: { label: "ST Ratio", prop: "st_ratio" },
        latest_intake: { label: "Latest Intake", prop: "latest_intake" },
        intake: { label: "Intake", prop: "intake" }
      };
    },
    getInnerRowData() {
      return (pondData) => {
        return this.innerTableData[pondData._id];
      };
    },
    locationToLocationIdMap() {
      return this.listOfAllLocations.reduce((acc, location) => {
        acc[location._id] = location;
        return acc;
      }, {});
    },
    getFirstLocationFromArr() {
      if (this.listOfAllLocations.length > 0) {
        return this.listOfAllLocations[0];
      }
      return { location_id: undefined };
    },
    selectedLocationId() {
      return (
        this.locationToLocationIdMap[this.upum__urlParams.location_id] || {
          _id: undefined
        }
      )._id;
    },
    selectedLocation() {
      return this.locationToLocationIdMap[this.selectedLocationId];
    }
  },
  watch: {
    selectedUser: function(newVal, oldVal) {
      this.initComponent(newVal);
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllLocationsofUser: "fetchAllLocationsofUser"
    }),
    async initComponent(input) {
      const { _id: user_id } = input;
      try {
        this.loading = true;
        if (user_id) {
          const locations = await this.fetchAllLocationsofUser({
            user_id: user_id
          });
          if (locations.user_locations.length > 0) {
            this.listOfAllLocations = locations.user_locations;
            if (!this.selectedLocationId) {
              this.upum__setUrlParams(
                "location_id",
                this.getFirstLocationFromArr._id
              );
            }
          }
          const params = {
            date: `${this.feedDate}T00:00:00.000Z`,
            get_all: true,
            location_id:
              this.selectedLocationId || this.getFirstLocationFromArr._id
          };
          const res = await SuperAdminService.fetchShrimpTalkSummary(
            user_id,
            params
          );
          // this.total = res.data.total;
          this.shrimpTalkSummary = res.data.data.sort((a, b) =>
            this.$commonUtils.alphaNumericComparator(a.title, b.title)
          );
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },

    initDate() {
      const userTimeZoneString =
        Object.keys(this.selectedUser).length > 0
          ? this.selectedUser.timezone.name
          : "UTC";
      this.feedDate = this.adm__dateUtilsLib.formatDate(
        this.adm__dateUtilsLib.startOfDay(
          this.adm__getTodayInUserTZ(userTimeZoneString)
        ),
        "yyyy-MM-dd"
      );
    },
    getFormatedResultwiseCounts(intake) {
      const total = Object.values(intake).reduce((sum, val) => sum + val, 0);
      const intakeList = {
        0: {
          key: 0,
          label: "No intake",
          percent: 0,
          color: "red"
        },
        1: {
          key: 1,
          label: "Poor intake",
          percent: 0,
          color: "orange"
        },
        2: {
          key: 2,
          label: "Good intake",
          percent: 0,
          color: "green"
        },
        3: {
          key: 3,
          label: "noise",
          percent: 0,
          color: "black"
        }
      };

      Object.entries(intake).forEach(([key, val]) => {
        intakeList[key].percent = (val / total) * 100;
      });

      return intakeList;
    },
    prevDayClick(date) {
      this.feedDate = date;
    },
    nextDayClick(date) {
      this.feedDate = date;
    },
    handlePresentDateChange(date) {
      this.feedDate = date;
    },

    async handleChangeInLocation(location) {
      this.loading = true;
      this.shrimpTalkSummary = [];
      await this.upum__setUrlParams("location_id", location._id);
      const params = {
        date: `${this.feedDate}T00:00:00.000Z`,
        get_all: true,
        location_id: this.selectedLocationId
      };
      const res = await SuperAdminService.fetchShrimpTalkSummary(
        this.selectedUser._id,
        params
      );
      // this.total = res.data.total;
      this.shrimpTalkSummary = res.data.data.sort((a, b) =>
        this.$commonUtils.alphaNumericComparator(a.title, b.title)
      );
      this.loading = false;
    },

    handleAddCustomerClick() {},
    async handleExpandChange(row) {
      this.expandloading = row._id;
      console.log(this.expandloading);
      const res = await this.getExpandedTableData(row);
      this.expandloading = "";
      this.$set(this.innerTableData, row._id, res);

      // this.innerTableData[row._id] = res;
    },
    getLatestIntake(data) {
      if (!data.settings) return "-";

      const code = data.settings.feeding_model;
      if (code === 0 && data.statistics_values) {
        return data.statistics_values.result;
      } else if (code === 1 && data.signature_statistics_values) {
        return data.signature_statistics_values.result;
      } else {
        return "-";
      }
    },
    getFormatedSTDetails(pondData, deviceData, indiFeeds, activeHours) {
      const data = [];
      pondData.shrimp_talks.forEach((st) => {
        const currentSTdeviceData = deviceData.shrimp_talks.find(
          (deviceST) => deviceST._id === st._id
        );
        const currentSTindiFeedData = indiFeeds.shrimp_talks[st._id];
        const currentSTActiveHoursData = activeHours.find(
          (activeHourST) => activeHourST._id === st._id
        );

        const masterPM = currentSTdeviceData.pond_mothers.find(
          (pm) => pm.is_master
        );

        data.push({
          // st_id: st._id,
          st_id: st._id,
          st_v1_code: `ST${currentSTdeviceData.v1_code}`,
          st_comm_status: currentSTdeviceData.comm_status,
          pm_id: "-",
          pm_master_id: masterPM ? masterPM._id : "",
          pm_title: "-",
          pm_ratio: "-",
          pm_feed_limit: 0,
          pm_disp_feed_limit: 0,
          latest_error_code: currentSTdeviceData
            ? currentSTdeviceData.sensor_values
              ? currentSTdeviceData.sensor_values.error_code
              : "-"
            : "-",
          st_hours_active: currentSTActiveHoursData.active_hours
            ? currentSTActiveHoursData.active_hours
            : 0,
          st_feed_limit: currentSTindiFeedData ? currentSTindiFeedData.feed : 0,
          st_disp_feed_limit: currentSTindiFeedData
            ? currentSTindiFeedData.dispensed_feed
            : 0,
          st_ratio: currentSTindiFeedData
            ? (currentSTindiFeedData.dispensed_feed /
                currentSTindiFeedData.feed) *
              100
            : 0,
          latest_intake: this.getLatestIntake(currentSTdeviceData),
          // latest_intake: this.colorCode[1].color,
          intake: currentSTActiveHoursData.result_wise_counts
            ? this.getFormatedResultwiseCounts(
                currentSTActiveHoursData.result_wise_counts
              )
            : "-",
          comm_status: currentSTdeviceData
            ? currentSTdeviceData.comm_status
            : true,
          pm_managed_by: "",
          st_last_communicated_at: currentSTdeviceData.last_communicated_at
            ? this.adm__dateUtilsLib.formatDate(
                new Date(currentSTdeviceData.last_communicated_at),
                "yyyy-MM-dd HH:mm:ss"
              )
            : "",
          pm_last_communicated_at: "",
          pm_gateway_code: ""

          // intake: this.getFormatedResultwiseCounts({
          //   0: 21,
          //   1: 130,
          //   2: 3,
          // }),
        });
      });

      pondData.pond_mothers.forEach((pm) => {
        const currentPMdeviceData = deviceData.pond_mothers.find(
          (devicePM) => devicePM._id === pm._id
        );
        const currentPMindiFeedData = indiFeeds.pond_mothers[pm._id];

        if (pm.shrimp_talk_id) {
          const st = data.find(({ st_id }) => st_id === pm.shrimp_talk_id);
          st.pm_title = pm.title;
          st.pm_id = pm._id;
          st.pm_ratio = currentPMindiFeedData
            ? (currentPMindiFeedData.dispensed_feed /
                currentPMindiFeedData.feed) *
              100
            : "-";
          st.pm_feed_limit = currentPMindiFeedData
            ? currentPMindiFeedData.feed
            : 0;
          st.pm_disp_feed_limit = currentPMindiFeedData
            ? currentPMindiFeedData.dispensed_feed
            : 0;
          st.pm_managed_by = pm.managed_by;
          st.pm_last_communicated_at = currentPMdeviceData.last_communicated_at
            ? this.adm__dateUtilsLib.formatDate(
                new Date(currentPMdeviceData.last_communicated_at),
                "yyyy-MM-dd HH:mm:ss"
              )
            : "";
          st.pm_gateway_code = currentPMdeviceData
            ? currentPMdeviceData.gateway_code
              ? currentPMdeviceData.gateway_code
              : ""
            : "";
        } else {
          data.push({
            st_id: "-",
            st_v1_code: "-",
            pm_id: pm._id,
            pm_master_id: "",
            pm_comm_status: currentPMdeviceData.comm_status,
            pm_title: pm.title,
            pm_feed_limit: currentPMindiFeedData
              ? currentPMindiFeedData.feed
              : 0,
            pm_disp_feed_limit: currentPMindiFeedData
              ? currentPMindiFeedData.dispensed_feed
              : 0,
            pm_ratio: currentPMindiFeedData
              ? (currentPMindiFeedData.dispensed_feed /
                  currentPMindiFeedData.feed) *
                100
              : "-",
            latest_error_code: currentPMdeviceData
              ? currentPMdeviceData.sensor_values
                ? currentPMdeviceData.sensor_values.error_code
                : "-"
              : "-",
            st_hours_active: "-",
            st_feed_limit: 0,
            st_disp_feed_limit: 0,
            st_ratio: "-",
            latest_intake: "-",
            intake: "-",
            comm_status: currentPMdeviceData
              ? currentPMdeviceData.comm_status
              : true,
            pm_managed_by: pm.managed_by,
            st_last_communicated_at: "",
            pm_last_communicated_at: currentPMdeviceData
              ? currentPMdeviceData.last_communicated_at
              : "",
            pm_gateway_code: currentPMdeviceData
              ? currentPMdeviceData.gateway_code
                ? currentPMdeviceData.gateway_code
                : ""
              : ""
          });
        }
      });

      return data;
    },
    async getExpandedTableData(pondData) {
      const { _id: pond_id } = pondData;
      const params = {
        date: `${this.feedDate}T00:00:00.000Z`
      };
      try {
        const deviceLatestCommRes = await SuperAdminService.fetchDeviceLatestCommDetails(
          pond_id
        );

        const indivdualFeedsPMandSTRes = await SuperAdminService.fetchIndividualFeedsPMandST(
          pond_id,
          params
        );

        const stActiveHoursRes = await SuperAdminService.fetchSTActiveHours(
          pond_id,
          params
        );

        const deviceLatestComm = deviceLatestCommRes.data.data;
        const indivdualFeedsPMandST = indivdualFeedsPMandSTRes.data.data;
        const stActiveHours = stActiveHoursRes.data.data;

        return this.getFormatedSTDetails(
          pondData,
          deviceLatestComm,
          indivdualFeedsPMandST,
          stActiveHours
        );
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        // this.expandloading = false;
      }
    },
    getRatio(despFeed, cibaFeed) {
      if (!cibaFeed & (cibaFeed !== 0)) {
        return "-";
      } else if ((despFeed === 0) & (cibaFeed === 0)) {
        return 0;
      } else {
        return ((despFeed / cibaFeed) * 100).toFixed(2);
      }
    },
    getRowKey(row) {
      return row._id;
    },
    getABWColor(wg) {
      if (wg > 0) {
        return "green";
      } else if (wg < 0) {
        return "red";
      } else {
        return "black";
      }
    },
    getRatioColor(ratio) {
      if (ratio > 100 || ratio < 75) {
        return "red";
      } else {
        return "black";
      }
    }
  }
};
</script>

<style lang="scss">
.st-feed-details {
  margin: 0 10px;
  @include responsiveProperty(--table-gutter, 190px, 190px, 215px);
  .user-details {
    border: 0.9px solid #6c7b8a3b;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .el-col:nth-child(1) {
    //   padding-left: 8px;
    // }
    // .el-col:nth-child(2) {
    //   margin-left: auto;
    // }
  }
  .el-table .el-table__cell {
    padding: 9px 0;
  }
  .progress-bar {
    color: white;
  }
  .dot {
    height: 10px;
    width: 10px;
    // background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .dot-small {
    height: 8px;
    width: 8px;
    // background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
  }
  .bar {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    color: black;
    &__filler {
      height: 6px;
      margin-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      // background: linear-gradient(225deg, #2d9cdb 0%, #155dbe 100%);
      // &:hover {
      //   background: linear-gradient(45deg, #2d9cdb 0%, #155dbe 100%);
      // }
    }
  }

  .settings-table .settings-table-scroll {
    position: relative;
    // @include responsiveProperty(height, 75vh, 79vh, 85vh);
    @include responsiveProperty(height, 64vh, 70vh, 76vh, true);
  }
}
.admin-st-feed-details-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}
.admin-st-feed-details-el-tooltip__popper.is-light[x-placement^="top"]
  .popper__arrow {
  border-top-color: #606266 !important;
  bottom: -7px;
}
.st-feed-details .el-table--fit {
  padding-left: 10px;
}
.st-feed-details .user-details {
  padding-left: 8px;
}
.st-feed-details .el-select {
  display: block;
}
</style>
