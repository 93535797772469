<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: PondMotherTab.vue
Description: This file has the table component that displays the pondmothers alloted to the dealer and show additional information about which user uses  the device, time it is selled.
-->
<template>
  <div
    class="dealer-pondmother-table"
    v-loading="loading"
    element-loading-background="white"
  >
    <div class="page_loading" v-if="loading"></div>
    <er-dialog
      width="35%"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      @open="handleOpenDialog"
      :title="ftm__capitalize($t('dealer.assign_pondmother'))"
      custom-class="pondMother_dialog"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form size="small" :inline="true">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <el-form-item
              :label="formStrings.selectedPMs.label"
              :error="errors[0]"
            >
              <er-select
                class="select-devices"
                :showSelectAll="true"
                v-model="selectedPMs"
                value-key="_id"
                :reserve-keyword="true"
                collapse-tags
                multiple
                filterable
                :placeholder="formStrings.selectedPMs.placeholder"
                remote
                :loading="listLoading"
                :remote-method="fetchDevicesToAssign"
              >
                <el-option
                  v-for="item in unassignedPMs"
                  :key="item._id"
                  :label="item.code"
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <el-form-item
              :label="formStrings.selectedUsers.label"
              :error="errors[0]"
            >
              <er-select
                class="select-devices"
                :showSelectAll="false"
                v-model="selectedUsers"
                value-key="_id"
                collapse-tags
                filterable
                :placeholder="formStrings.selectedUsers.placeholder"
                remote
                :loading="listLoading"
                :remote-method="fetchUsersToAssign"
              >
                <el-option
                  v-for="item in unassignedUsers"
                  :key="item._id"
                  :label="
                    `${item.first_name} ${item.last_name} (${item.email})`
                  "
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>

      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="handleAssignDevicesToUser(selectedPMs, 'POND_MOTHER')"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :disabled="loading"
          @click="handleCloseDialog"
        ></er-button>
      </template>
    </er-dialog>
    <layout-dealer-card
      v-show="!loading"
      search-property="device_code"
      :queryParams="queryParams"
      @add-item-click="handleAddDealerClick"
      @loadData="handleLoadData"
    >
      <template v-slot:heading>
        <div class="main_header">
          <img src="@/assets/dealer/devices.svg" atl="down arrow" />
          <p style="margin-left: 10px">
            {{ $t("Comn_pond_mother") }}
          </p>
        </div>
      </template>
      <template slot="table">
        <defaultTable
          v-show="!loading"
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          class="add_PondMother"
          :key="$i18n.locale"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
        >
          <template v-slot:first_name_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:actions_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>

          <template v-slot:status_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>

          <template v-slot:code_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>

          <template v-slot:added_date_header="{ header }">
            <span>{{ $t(header.label) }}</span>
          </template>
          <template v-slot:first_assigned_date_header="{ header }">
            <el-tooltip
              :content="$t(header.sub_label)"
              placement="top"
              effect="light"
            >
              <p>{{ $t(header.label) }}</p>
            </el-tooltip>
          </template>
          <template v-slot:last_assigned_date_header="{ header }">
            <el-tooltip
              :content="$t(header.sub_label)"
              placement="top"
              effect="light"
            >
              <p>{{ $t(header.label) }}</p>
            </el-tooltip>
          </template>
          <template v-slot:first_user_assigned_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.first_user_assigned_date)) }}
          </template>
          <template v-slot:last_user_assigned_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.last_user_assigned_date)) }}
          </template>
          <!-- <template v-slot:updated_at="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.updated_at)) }}
          </template> -->
          <template v-slot:status="{ row }">
            {{ getStatus(row.data, "POND_MOTHER") }}
          </template>
          <template v-slot:code="{ row }">
            {{ row.data.code }}
          </template>
          <template v-slot:first_name="{ row }">
            {{
              row.data.user_details
                ? row.data.user_details.first_name
                : getDealerUsername.first_name
            }}
            {{
              row.data.user_details
                ? row.data.user_details.last_name
                : getDealerUsername.last_name
            }}
            <br />
            {{
              row.data.user_details
                ? row.data.user_details.email
                : getDealerUsername.email
            }}
          </template>
          <template v-slot:added_date="{ row }">
            {{ ftm__formatEmpty(adm__filterToFormatDate(row.data.added_date)) }}
          </template>

          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button
                size="mini"
                @click="handleDeviceDelete(row.data, 'POND_MOTHER')"
                :disabled="!Boolean(row.data.user_details)"
              >
                <span class="material-icons-round icon__prefix"> delete </span>
              </er-button>
            </el-button-group>
          </template>
        </defaultTable>
      </template>
    </layout-dealer-card>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import defaultTable from "./defaultTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import fitersMixin from "@/mixins/filtersMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import dealerDevicesMixin from "./dealerDevicesMixin";
import layoutDealerCard from "./layoutDealerCard";
export default {
  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    notificationMixin,
    fitersMixin,
    adminDatesHandlerMixin,
    dealerDevicesMixin("CONTAINER")
  ],
  props: ["dealer_id"],
  data: function() {
    return {
      tableData: [],
      listLoading: false,
      unassignedPMs: [],
      unassignedUsers: [],
      userSearchQuery: {},
      pmSearchQuery: {},
      selectedUsers: [],
      total: 0,
      selectedPMs: [],
      dialogVisible: false,
      loading: false,
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      //  formVendor: this.vendor,
      newErrObject: {
        selectedPMs: [],
        selectedUsers: ""
      },
      ehm__errMessagesObject: {
        selectedPMs: [],
        selectedUsers: ""
      },
      params: {
        get_all: true
      }
    };
  },
  components: {
    defaultTable,
    layoutDealerCard
  },
  computed: {
    ...mapGetters("dealer", {
      getDealerAssignedPms: "getDealerAssignedPms",
      getDealerUsers: "getDealerUsers",
      getDealerUnassignPms: "getDealerUnassignPms"
    }),
    getDealerUsername() {
      return this.$store.getters["dealer/getDealerProfile"];
    },
    formStrings() {
      return {
        selectedUsers: {
          label: this.$t("DL_select_user"),
          placeholder: this.$t("Comn_select")
        },
        selectedPMs: {
          label: this.$t("Comn_pond_mother"),
          placeholder: this.$t("Comn_select")
        }
      };
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          name: "code",
          label: "Comn_Device_ID",
          required: true,
          sortable: true
        },

        {
          prop: "first_name",
          name: "first_name",
          label: "DL_assigned_customer",
          required: true,
          sortable: false
        },
        {
          prop: "first_user_assigned_date",
          name: "first_assigned_date",
          label: "DL_first_assigned_date",
          sub_label: "DL_first_assigned_date_content",
          required: true,
          sortable: false
        },
        {
          prop: "last_user_assigned_date",
          name: "last_assigned_date",
          label: "DL_last_assigned_date",
          sub_label: "DL_last_assigned_date_content",
          required: true,
          sortable: false
        },
        // {
        //   prop: "updated_at",
        //   name: "added_date",
        //   label: "DL_assigned_date",
        //   required: true,
        //   sortable: false
        // },
        {
          name: "status",
          prop: "status",
          label: "device_status",
          required: true,
          sortable: true
        },
        {
          label: "Comn_actions",
          prop: "actions",
          name: "actions",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("dealer", {
      fetchDealerAssignedDevices: "fetchDealerAssignedDevices",
      fetchAllUnassignedDealerDevices: "fetchAllUnassignedDealerDevices",
      fetchDealerRelatedUsers: "fetchDealerRelatedUsers",
      addPondMotherToUser: "addPondMotherToUser",
      deletePondMotherFromUser: "deletePondMotherFromUser"
    }),
    async fetchDevicesToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.listLoading = true;
        this.pmSearchQuery.device_code = queryString;
        await this.fetchAllUnassignedDealerDevices({
          infinite_scroll: false,
          device_type: "pond_mother",
          params: this.pmSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.listLoading = false;
        this.unassignedPMs = this.getDealerUnassignPms;
      }
    },
    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.listLoading = true;
        this.userSearchQuery.user_name = queryString;
        await this.fetchDealerRelatedUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.listLoading = false;
        this.unassignedUsers = this.getDealerUsers;
      }
    },

    initValidationStrings() {
      extend("required", {
        ...required,
        message: this.$t("error_msg")
      });
    },
    async handleLoadData(query) {
      try {
        this.queryParams = query;
        this.loading = true;
        const response = await this.fetchDealerAssignedDevices({
          params: query,
          infinite_scroll: false,
          device_type: "pond_mother"
        });
        this.tableData = this.getDealerAssignedPms;
        this.total = response.total;
        this.loading = false;
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleAddDealerClick() {
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleOpenDialog(event) {
      this.initValidationStrings();
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
      this.selectedUsers = [];
      this.selectedPMs = [];
      this.unassignedPMs = [];
      this.unassignedUsers = [];
    }
  }
};
</script>

<style lang="scss">
.dealer-pondmother-table {
  height: 100%;
  .page_loading {
    @include responsiveProperty(height, 100vh, 100vh, 100vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .main_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
}
.pondMother_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 260px, 300px, 350px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 130px, 180px, 210px);
  }
}
</style>
