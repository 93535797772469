<template>
  <el-row
    v-loading="loading"
    class="sub-user-details-form"
    ref="subUserDetailsForm"
  >
    <!-- class="right-panel-content-alignment" -->
    <el-form
      label-position="left"
      :key="`${editType}_${getUserId}`"
      size="mini"
      :inline="true"
    >
      <el-row class="sub-user-type-selection">
        <el-form-item
          label="Status"
          v-if="editType !== 'CREATE'"
        >
          <er-select
            v-model="user.status"
            placeholder="Select Status"
            @change="handleChangeInStatus"
          >
            <template v-for="(value, index) in this.statusData">
              <el-option
                :key="index"
                :label="value.label"
                :value="value.key"
              ></el-option>
            </template>
          </er-select>
        </el-form-item>
        <el-form-item
          label="Department"
        >
          <er-select
            :value="departmentVal"
            placeholder="Select Department"
            @change="handleChangeInUserType"
          >
            <template v-for="(value, index) in this.availableUserTypes">
              <el-option
                :key="index"
                :label="value.label"
                :value="value.key"
              ></el-option>
            </template>
          </er-select>
          <!-- <div class="sub-user-type-description" v-if="user.user_type">
            <span class="material-icons-outlined">info</span>
            <p>
              {{
                $tc(
                  $gblUAMUsrRoleToUsrRoleDetailsObj[user.user_type].description,
                  1
                )
              }}
            </p>
          </div> -->
        </el-form-item>
      </el-row>
      <el-row class="sub-user-basic-info">
        <layout-toolbar justify="start" class="sub-user-details-header">
          <span class="material-icons-outlined color-variable">info</span>
          <p class="sub-user-details-header__title color-variable">
           User Details
          </p>
        </layout-toolbar>
        <el-form-item
          prop="first_name"
          label="First Name"
          :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.first_name)"
        >
          <er-input
            v-model="user.first_name"
            class="input-box"
            placeholder="Enter First Name"
          ></er-input>
        </el-form-item>
        <el-form-item
          prop="last_name"
          label="Last Name"
          :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.last_name)"
        >
          <er-input
            v-model="user.last_name"
            class="input-box"
            placeholder="Enter Last Name"
          ></er-input>
        </el-form-item>
        <el-form-item
          prop="email"
          label="Email Address"
          :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.email)"
        >
          <er-input
            v-model="user.email"
            class="input-box"
            placeholder="Enter Email Address"
          ></er-input>
        </el-form-item>
        <el-form-item
          prop="phone"
          label="Mobile Number"
          :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.phone)"
        >
          <el-input
            class="country-select input-box"
            v-model="user.phone"
            placeholder="Enter Mobile Number"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Country"
          :error="
            superUser__castErrorKeyToLang(
              ehm__errMessagesObject.country.name
            )
          "
        >
          <er-select
            v-model="user.country"
            value-key="code"
            autocomplete="new-country"
            filterable
            placeholder="Select Country"
            @change="handleCountryChange($event, 'SELECT_CHANGED')"
          >
            <er-option
              v-for="(name, code) in countries"
              :key="code"
              :label="ftm__capitalize(name)"
              :value="{ name, code }"
            ></er-option>
          </er-select>
        </el-form-item>
        <el-form-item
          label="Timezone"
          :error="
            superUser__castErrorKeyToLang(ehm__errMessagesObject.timezone)
          "
        >
          <er-select
            v-model="user.timezone"
            autocomplete="new-timezone"
            value-key="name"
            filterable
            placeholder="Select Timezone"
            @change="handleChangeTimeZone"
          >
            <er-option
              v-for="(timezone, name) in getTimeZones"
              :key="name"
              :label="ftm__capitalize(name)"
              :value="timezone"
            ></er-option>
          </er-select>
        </el-form-item>
        <template v-if="editType === 'CREATE'">
          <el-form-item
            prop="password"
            label="Password"
            :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.password)"
          >
            <er-input
              v-model="user.password"
              placeholder="Enter Password"
              show-password
              autocomplete="new-password"
              class="er-input--password"
              ref="passwordField"
              @input="pvm__getPercentage"
              :style="`--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`"
            ></er-input>
          </el-form-item>
          <el-form-item
            prop="confirm_password"
            label="Confirm Password"
            :error="superUser__castErrorKeyToLang(ehm__errMessagesObject.confirm_password)"
          >
            <er-input
              v-model="user.confirm_password"
              class="input-box"
              placeholder="Enter Confirm Password"
              @keyup.native.enter="submitForm"
              show-password
            ></er-input>
          </el-form-item>
        </template>
      </el-row>
      <el-row
        v-if="editType === 'CREATE'"
        class="password-validations-container right-panel-content-alignment"
      >
        <span class="header">
          Password Selection Guidelines:
        </span>
        <ul class="validation-rules">
          <li
            v-for="(validation, id) in pvm__passwordValidations"
            :key="id"
            :class="[
              'validation-rules-row',
              validation.validator() && 'is-checked',
            ]"
          >
            <div class="title">
              <p>{{ ftm__capitalize(validation.content) }}</p>
            </div>
          </li>
        </ul>
      </el-row>
      <adminPageAccessComponent
        :layout="['permissions']"
        :permissions-list="getAdminPermissions"
        :user-details="user"
        @permissions-changed="handlePermissionsChange"
        :editable="true"
      ></adminPageAccessComponent>
    </el-form>
    <layout-toolbar
      type="flex"
      justify="end"
      class="btn-container right-panel-content-alignment"
    >
      <er-button
        size="mini"
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        @click="submitForm"
        :loading="loading"
      ></er-button>
      <er-button
        size="mini"
        btnType="cancel"
        :showLabel="true"
        @click="resetForm"
        :loading="loading"
      ></er-button>
    </layout-toolbar>
  </el-row>
</template>

<script>
import User from "../../../model/user";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import { mapGetters, mapActions } from "vuex";
import {
  FTPermissionsToActions
} from "@/middleware/pageAccessManager";
import { ADMIN_USER_TYPES } from "@/constants/filterTypes";
import { ADMIN_USER_UI_STATES } from "@/constants/uiStates";
import passwordValidationMixin from "@/mixins/passwordValidationMixin.vue";
import adminPageAccessComponent from "./adminPageAccessComponent.vue";
export default {
  props: ["editType"],
  inject: ["parentConfig"],
  mixins: [errorHandlerMixin, errorKeyMapMixin, passwordValidationMixin],
  components: { adminPageAccessComponent },
  data() {
    return {
      loading: false,
      user: new User(),
      departmentVariable: ADMIN_USER_TYPES[0]?.key,
      status: 'ACTIVE',
      ehm__errMessagesObject: new User(),
      FTPermissionsToActions,
      statusData: [
        { key: "ACTIVE", label: "Active" },
        { key: "INACTIVE", label: "Inactive" },
      ],
      // ekmm__backndfieldToFieldNameMap: {
      //   email: "Comn_email"
      // },
      // ekmm__backndfieldToInvalidMap: {
      //   email: {
      //     INVALID_USER_DATA: "Usrs_email_already_exists"
      //   }
      // },
      userTypeToPermissionLayout: {},
      userPayloadSchema: [
        {
          prop: "first_name",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "last_name",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "email",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "phone",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "department",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "status",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "password",
          action: ["CREATE"]
        },
        {
          prop: "confirm_password",
          action: ["CREATE"]
        },
        {
          prop: "country",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "timezone",
          action: ["CREATE", "UPDATE"]
        },
        {
          prop: "permissions",
          action: ["CREATE", "UPDATE"]
        }
      ],
      countries: {},
      selectedPermissions: []
    };
  },
  watch: {
    getUserId: function () {
      this.ehm__clearErrorMessages(new User());
      this.handleChangeInUser();
    }
  },
  computed: {
    ...mapGetters("superadmin", {
      getSidePanelState: "getSidePanelState",
      getAdminPermissions: "getAdminPermissions"
    }),
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    departmentVal() {
      return this.departmentVariable;
    },
    // locations() {
    //   return this.$lodash.cloneDeep(this.getUserLocations);
    // },
    // locationIds() {
    //   return this.locations.map((location) => location._id);
    // },
    availableUserTypes() {
      return ADMIN_USER_TYPES;
    },
    getTimeZones() {
      const timezonesArr = Object.values(
        this.$store.getters["user/getTimeZones"]
      ).map((x) => ({
        name: x.name,
        offset: x.offset
      }));
      const timeZoneObj = {};
      timezonesArr.forEach((x) => {
        timeZoneObj[x.name] = x;
      });
      return timeZoneObj;
    },
    // getUserTypeLangStrs() {
    //   return this.parentConfig.USER_TYPE;
    // },
    getUserData() {
      return this.getSidePanelState.userDetails;
    },
    getUserId() {
      return this.getUserData._id;
    },
    getUAMTabData() {
      return this.parentConfig.uamTabData;
    },
    pvm__passwordValidations() {
      const password = this.user.password || "";
      return this.pvm__superUserPasswordFieldValidations(password);
    }
  },
  created() {
    this.handleChangeInUser();
  },
  async mounted() {
    try {
      await this.fetchCountryList();
      this.countries = this.$store.getters["auth/getCountries"];
      this.setRefsForPasswordValidations(
        this.$refs.subUserDetailsForm.$el,
        this.$refs.passwordField
      );
    } catch (err) {
      this.ehm__errorMessages(err, false);
    }
  },
  methods: {
    ...mapActions("superadmin", {
      restoreToIntialState: "restoreToIntialState",
      updateSuperUser: "updateSuperUser",
      createSuperUser: "createSuperUser",
      fetchUserProfile: "fetchUserProfile"
    }),
    ...mapActions("auth", {
      fetchCountryList: "fetchCountryList"
    }),
    async handleCountryChange(selectedCountry, action = "INIT") {
      await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
      if (action === "INIT" && this.user.timezone) {
        return;
      }
      this.user.timezone = this.getTimeZones[Object.keys(this.getTimeZones)[0]];
    },
    handleChangeTimeZone(timezone) {
      this.user.timezone = timezone;
    },
    getPayload(user) {
      return this.userPayloadSchema.reduce((userObj, currProp) => {
        // const conditions = [
        //   currProp.action.includes(this.editType) &&
        //     currProp.user_type.includes(user.user_type)
        // ];
        // if (conditions.some((x) => x)) {
          userObj[currProp.prop] = this.user[currProp.prop];
        // }
        return userObj;
      }, {});
    },
    handleChangeInUserType(data) {
      this.user.department = data;
      this.departmentVariable = data;
    },
    handleChangeInStatus(data) {
      this.user.status = data
    },
    handleChangeInUser() {
      this.user = this.$lodash.cloneDeep(this.getUserData);
      this.handleCountryChange(this.user.country, "INIT");
      if (this.editType === "CREATE") {
        this.user.department = ADMIN_USER_TYPES[0]?.key;
        this.user.status = 'ACTIVE';
      } else {
        this.departmentVariable = this.user.department;
      }
    },
    // handleLocationsChange(changedLocations) {
    //   this.user.allowed_locations = changedLocations;
    // },
    handlePermissionsChange(changedPermissions) {
      this.selectedPermissions = [];
      this.selectedPermissions = changedPermissions.filter(ele => ele.value);
    },
    async resetForm() {
      this.user = new User();
      // this.user.allowed_locations = [this.locationIds[0]];
      await this.restoreToIntialState({
        shallFetchData: false,
        sidePanelUserId: this.getUserData._id,
        sidePanelUIState: ADMIN_USER_UI_STATES.DISPLAY_SUB_USER_DETAILS
      });
    },
    ehm__error409Handler: function (err) {
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
      }
    },
    ehm__error403Handler: function(err) {
    const errorData = err.response.data;
      if (errorData.error_code === "ACCOUNT_ALREADY_TAKEN") {
        this.$notify({
          title: this.$t("failed"),
          message: errorData.message,
          type: "error"
        });
      }
    },
    async submitForm() {
      try {
        this.user.permissions = ["HELP"];
        await this.selectedPermissions.map(ele => {
          this.user.permissions.push(ele.key);
        })
        if (this.editType !== "CREATE") {
          var payload = this.getPayload(this.user)
          payload.dealer_id = this.user._id;
          if (this.user.permissions.length === 1) {
             payload.permissions = await this.getUserData.permissions;
          }
        }
        this.$emit("loading", true);
        this.user.first_name = this.upm__trimSpacesInStr(this.user.first_name);
        this.user.last_name = this.upm__trimSpacesInStr(this.user.last_name);
        const modeToStoreFunc = {
          CREATE: {
            func: "createSuperUser",
            params: this.getPayload(this.user),
            success_message: "New User Created Successfully"
          },
          UPDATE: {
            func: "updateSuperUser",
            params: {
              userId: this.user._id,
              payload: payload
            },
            success_message: "User Details  Updated Successfully"
          }
        };
        this.ehm__clearErrorMessages(new User());
          const response = await this[modeToStoreFunc[this.editType].func](
            modeToStoreFunc[this.editType].params
          );
          this.$notify({
            title: "Success",
            message: this.$t(modeToStoreFunc[this.editType].success_message),
            duration: 5000,
            type: "success"
          });
          console.log("response.user._id", response);
          await this.$emit("statusChange", {
            status: "SUCCESS",
            userId: this.editType === "CREATE" ? response.user._id : this.user._id
          });
         this.fetchUserProfile();
      } catch (err) {
        this.ehm__errorMessages(err, true);
        if (err.type === "FAIL_TO_SAVE") return;
        this.$emit("statusChange", { status: "FAILED" });
      } finally {
        this.selectedPermissions = [];
        this.user.permissions = ["HELP"];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_passwordvalidation.scss";
.sub-user-details-form {
  @include password-validation-mixin;
  &::v-deep .el-form-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    // $label-width: 100px;
    .el-form-item__label {
      @include responsiveProperty(width, 108px, 120px, 150px);
      @include small-text;
    }
    .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);
      .el-input,
      .el-input .el-input__inner,
      .el-select {
        @include small-text;
        width: 100%;
      }
    }
  }
  &::v-deep .er-input.er-input--password:after {
    content: "";
    position: absolute;
    width: var(--width);
    background-color: var(--color);
    height: 2px;
    bottom: 0px;
    left: var(--left);
    padding-left: 0px;
    transition: width 0.5s ease-out;
  }
  &::v-deep .sub-user-details-header {
    padding: 5px 15px;
    font-weight: 700;
    @include normal-text;
  }
  &::v-deep .sub-user-type-selection {
    padding-top: 25px;
    .el-form-item .el-form-item__content {
      @include responsiveProperty(width, 57%, 50%, 45%);
    }
    .sub-user-type-description {
      display: inline-flex;
      flex-direction: row;
      @include v-small-text;
      white-space: break-word;
      line-height: 1;
      margin-top: 10px;
      & > * {
        align-self: center;
      }
      .material-icons-outlined {
        margin-right: 5px;
        @include small-text;
      }
    }
  }
}
</style>
