<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: customerGatewayAG.vue
Description:This file contains the details the customer gateways with column wise filters
-->
<template>
  <div class="pm-filter-main-tab" v-loading="loading">
    <div class="item-container is-filters-set-relative">
      <div class="table-filters-right">
        <er-select-with-options
          :value="selectedPage"
          :options="pageFilterOptions"
          value-type="string"
          value-key="label"
          label-key="label"
          placeholder="Select Page"
          @input="handlePageSelectionChange"
          class="select-md"
          :disabled="loading"
        >
        </er-select-with-options>
        <er-download-drop-down
          :downloadTypes="['EXCEL']"
          @download-action="handleDownloadExcel"
          class="height-auto"
        ></er-download-drop-down>
        <er-column-selector
          :columns="computedColumns"
          keyToStoreDefaults="pond_mother_filters"
          keyToDecideDefaultCol="isVisible"
          :allow-check="true"
          :title="'Column Visibility'"
          @change-selection="handleChangeColumnSelection"
        ></er-column-selector>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridGatewayFilters"
      style="width: 100%; height: calc(100vh - 120px)"
      class="ag-theme-alpine"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :pagination="true"
      :frameworkComponents="frameworkComponents"
      :rowData="deviceData"
      v-if="show"
    >
    </ag-grid-vue>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { mapActions } from "vuex";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import agGridStringCompareFilter from "@/components/superadmin/shared/agGridStringCompareFilter";
import agGridLastCommCellRenderer from "@/components/superadmin/shared/agGridLastCommCellRenderer";
import XLSX from "xlsx";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import ErSelectWithOptions from "../../base/erSelectWithOptions.vue";
export default {
  mixins: [adminDatesHandlerMixin, errorHandlerMixin, filtersMixin],
  data() {
    return {
      columnDefs: null,
      rowData: null,
      frameworkComponents: null,
      loading: false,
      deviceDetails: [],
      gridOptions: {},
      gridApi: undefined,
      gridColumnApi: undefined,
      show: true,
      pageFilterOptions: [],
      selectedPage: "0 -  10000",
      currentPagenation: {
        page: 1,
        limit: 10000,
      },
      total: 0,
    };
  },
  components: {
    AgGridVue,
    ErSelectWithOptions,
  },
  computed: {
    computedColumns: function () {
      const columns = this.$lodash.cloneDeep(
        this.columnDefs.reduce((acc, curr) => {
          acc[curr.colId] = curr;
          return acc;
        }, {})
      );
      // Object.keys(columns).forEach(x => {
      //   columns[x].label = this.$t(columns[x].label);
      // });
      Object.keys(columns).forEach((x) => {
        columns[x].label = columns[x].headerName;
      });
      return columns;
    },
    deviceData: function () {
      return this.$lodash.cloneDeep(this.deviceDetails).map((x) => {
        ["device_ui_edit", "retain_previous_day_schedule"].forEach((key) => {
          if (x.settings && x.settings[key]) {
            x.settings[key] = 1;
          } else {
            if (!x.settings) {
              x.settings = {};
            }
            x.settings[key] = 0;
          }
        });
        x.customer_name = x.user_details ? x.user_details.full_name : "--";
        return x;
      });
    },
  },
  async created() {
    await this.initComponent();

    if (this.total) {
      const limit = 10000;
      let start = 0;
      let page = 1;
      for (; start < this.total; start = start + limit, page++) {
        this.pageFilterOptions.push({
          label: `${start} -  ${
            start + limit <= this.total ? start + limit : this.total
          }`,
          value: {
            page,
            limit,
          },
        });
      }
    }
  },
  async beforeMount() {
    this.columnDefs = [
      {
        headerName: "PondMother Code",
        isVisible: true,
        colId: "pondMother_code",
        field: "code",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Alias",
        isVisible: true,
        colId: "title",
        field: "title",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Customer Name",
        isVisible: true,
        colId: "customer_name",
        field: "customer_name",
        sortable: true,
        filter: true,
      },
      {
        colId: "dealer_details.full_name",
        field: "dealer_details.full_name",
        headerName: "Dealer Name",
        isVisible: true,
        minWidth: 200,
      },
      {
        colId: "user_details.timezone.name",
        field: "user_details.timezone.name",
        headerName: "Customer Timezone",
        isVisible: true,
        required: true,
        sortable: true,
        filter: true,
      },
      {
        colId: "location_details.name",
        field: "location_details.name",
        headerName: "Location",
        isVisible: true,
        minWidth: 200,
        required: true,
        sortable: true,
        filter: true,
      },
      {
        colId: "device_timezone",
        field: "device_timezone",
        headerName: "Device Timezone",
        isVisible: true,
        required: true,
        sortable: true,
        filter: true,
      },
      {
        colId: "pond_details.title",
        field: "pond_details.title",
        headerName: "Pond Name",
        isVisible: true,
        required: true,
        sortable: true,
        filter: true,
      },
      {
        headerName: "UI Edit",
        isVisible: true,
        colId: "device_ui_edit",
        field: "settings.device_ui_edit",
        sortable: true,
        filter: true,
        valueFormatter: this.handleDeviceUiEdit,
      },
      {
        headerName: "Prev Day Schedules",
        isVisible: true,
        colId: "retain_previous_day_schedule",
        field: "settings.retain_previous_day_schedule",
        sortable: true,
        filter: true,
        valueFormatter: this.handlePrevDaySchedules,
      },
      {
        headerName: "V1_id",
        isVisible: true,
        colId: "v1_id",
        field: "v1_id",
        sortable: true,
        filter: true,
      },
      {
        headerName: "V2_id",
        isVisible: true,
        colId: "_id",
        field: "_id",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Feeder Type",
        isVisible: true,
        colId: "hopper_type",
        field: "settings.hopper_type",
        label: "Feeder Type",
        required: true,
        minWidth: 200,
        filter: true,
        valueFormatter: this.handleFeederType,
      },
      {
        headerName: "Gateway Code",
        isVisible: true,
        colId: "gateway_code",
        field: "gateway_code",
        label: "Gateway Code",
        sortable: true,
        required: true,
        minWidth: 120,
        filter: true,
      },
      {
        headerName: "Software Version",
        isVisible: true,
        colId: "software_version",
        field: "settings.software_version",
        sortable: true,
        filter: "agGridStringCompareFilter",
      },
      {
        headerName: "Hardware Version",
        isVisible: true,
        colId: "hardware_version",
        field: "settings.hardware_version",
        sortable: true,
        filter: true,
      },
      {
        headerName: "Mechanical Version",
        isVisible: true,
        colId: "mechanical_version",
        field: "settings.mechanical_version",
        label: "Mechanical Version",
        required: false,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Shrimp talk Id",
        isVisible: true,
        colId: "shrimp_talk_code",
        field: "shrimp_talk_code",
        label: "Shrimp talk Id",
        required: true,
        minWidth: 100,
        filter: true,
      },
      {
        headerName: "Installed date",
        isVisible: true,
        colId: "on_boarded_at",
        field: "on_boarded_at",
        label: "Installed date",
        required: true,
        sortable: true,
        type: "date",
        minWidth: 150,
        filter: true,
      },
      {
        headerName: "Last Communication",
        isVisible: true,
        cellRenderer: "agGridLastCommCellRenderer",
        colId: "utc_last_communicated_at",
        field: "utc_last_communicated_at",
        sortable: true,
        filter: "agDateColumnFilter",
        width: 230,
        filterParams: {
          comparator: (inputDate, cellValue) => {
            const cellDate = this.adm__dateUtilsLib.parse(
              cellValue,
              this.adm__dateUtilsLib.isoFormatString,
              new Date()
            );
            const formattedInputDate = inputDate;
            console.log(
              "formattedInputDate,cellDate ",
              formattedInputDate,
              cellDate
            );
            if (
              this.adm__dateUtilsLib.isBefore(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return -1;
            } else if (
              this.adm__dateUtilsLib.isAfter(
                this.adm__dateUtilsLib.startOfDay(cellDate),
                formattedInputDate
              )
            ) {
              return 1;
            }
            return 0;
          },
          buttons: ["apply", "clear"],
        },
      },
    ];
    this.frameworkComponents = {
      agGridStringCompareFilter: agGridStringCompareFilter,
      agGridLastCommCellRenderer: agGridLastCommCellRenderer,
    };
    this.rowData = this.deviceDetails;
  },
  mounted() {
    // this.initComponent();
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi.columnModel
      ? this.gridOptions.columnApi.columnModel
      : this.gridOptions.columnApi.columnController;
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
    }),

    async handlePageSelectionChange(selectedPage) {
      this.selectedPage = selectedPage;
      const pageDetails = this.pageFilterOptions.find(
        (e) => e.label === selectedPage
      );
      this.currentPagenation = pageDetails.value;
      const savedFilterModel = this.gridApi.getFilterModel();

      await this.fetchData();
      this.$nextTick(() => {
        if (
          this.$refs.agGridGatewayFilters &&
          this.gridColumnApi &&
          savedFilterModel
        ) {
          this.gridOptions.api.setFilterModel(savedFilterModel);
        }
        this.loading = false;
      });
    },
    handleChangeColumnSelection(selectedColumnsId) {
      const allColumns = this.columnDefs.map((col, id) => {
        return col.colId;
      });
      const columnsToHide = allColumns.filter(
        (x) => !new Set(selectedColumnsId).has(x)
      );
      this.$nextTick(() => {
        if (
          this.$refs.agGridGatewayFilters &&
          this.gridColumnApi &&
          selectedColumnsId &&
          selectedColumnsId.length > 0
        ) {
          this.gridColumnApi.setColumnsVisible(selectedColumnsId, true);
          this.gridColumnApi.setColumnsVisible(columnsToHide, false);
        }
      });
    },
    handlePrevDaySchedules(params) {
      return params.data.settings.retain_previous_day_schedule;
    },
    handleFeederType(params) {
      let feederType = '';
      if (params.data.settings.hopper_type === 'PM250-MAX-C') {
        feederType = 'PM250 MAX (C)';
      } else if (params.data.settings.hopper_type === 'PM250-MAX-S') {
        feederType = 'PM250 MAX (S)';
      } else {
        feederType = params.data.settings.hopper_type;
      }
      return feederType;
    },
    handleDeviceUiEdit(params) {
      return params.data.settings.device_ui_edit;
    },
    getFormattedExcelDataVal(data, field) {
      let retVal = this.$lodash.get(data, field);
      if (field === "last_communicated_at") {
        retVal = this.ftm__formatEmpty(this.adm__filterToFormatDate(retVal));
      }
      return retVal;
    },
    handleDownloadExcel() {
      const columns = this.gridColumnApi.gridColumns.map((column) => [
        column.colDef.field,
        column.colDef.headerName,
      ]);
      const xlData = [];
      this.gridApi.forEachNodeAfterFilter((rowNode, index) => {
        xlData.push(
          columns.reduce((acc, [field, headerName]) => {
            let cellDate = "";

            if (headerName === "Last Communication") {
              try {
                const data = this.$lodash.get(rowNode.data, field);
                const IstTimeZone = "Asia/Kolkata";
                if (data) {
                  cellDate = this.ftm__formatEmpty(
                    this.adm__formatByTZOnUTCISOWithLocale(
                      data,
                      "dd-MMM-yy HH:mm",
                      IstTimeZone
                    )
                  );
                }
              } catch {
                // cellDate = "";
              }
              acc[headerName] = cellDate;
            } else if (headerName === "Feeder Type") {
              const feederType = this.$lodash.get(rowNode.data, field);
              if (feederType === 'PM250-MAX-C') {
                 acc[headerName] = 'PM250 MAX (C)';
              } else if (feederType === 'PM250-MAX-S') {
                acc[headerName] = 'PM250 MAX (S)';
              } else {
                acc[headerName] = feederType;
              }
            } else {
              acc[headerName] = this.$lodash.get(rowNode.data, field);
            }
            return acc;
          }, {})
        );
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, "PondMotherFilters.xlsx", {
        type: "binary",
        cellDates: true,
      });
    },
    async initComponent() {
      try {
        this.loading = true;
        // we have to fetch counts first
        const response = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          params: {
            page: 1,
            limit: 10000,
          },
          preventApiParms: true
        });
        this.deviceDetails = this.$store.getters["superadmin/getPondMothers"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    async fetchData() {
      try {
        this.loading = true;
        // we have to fetch counts first
        const response = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          params: {
            page: this.currentPagenation.page,
            limit: this.currentPagenation.limit,
          },
          preventApiParms: true
        });
        this.deviceDetails = this.$store.getters["superadmin/getPondMothers"];
        console.log("this.deviceDetails", this.deviceDetails);
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .pm-filter-main-tab {
//   margin-top: 54px;
// }

// .er-column-selector {
//   display: flex;
//   justify-content: flex-end;
// }
.item-container {
  display: flex;
  justify-content: space-between;
  // margin-top: 50px;
  .select-md {
    max-width: 150px;
  }
}
</style>
