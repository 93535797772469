<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondmotherTableSA.vue
Description:This file contains the details of the pondmother devices
-->
<template>
  <div>
    <!-- <dialog-form
      :model="getNewPondMother()"
      :schema="pondMotherFields"
      :showDialog="dialogVisible"
      :loading="dialogBoxLoading"
      title="Add Pond Mother"
      :backendValidations="createPMFormErrors"
      @close-dialog="closeDialog"
      :v1-code-length="6"
      @save-details="handleSaveNewPondMother"
      @clear-validations="handleClearValidations"
      deviceType="POND_MOTHER"
      :shouldErrorHighlightShow="true"
    >
    </dialog-form> -->

    <settings-table
      ref="erDataTables"
      class="pm-setting-table-main-container is-filters-set-absolute"
      v-loading="loading"
      element-loading-background="white"
      :tableData="tableData"
      :columns="columnsObject"
      :tableProps="elTableProps"
      :addPadingToSearch="true"
      :total="total"
      :button="false"
      :download="true"
      :columnselector_require="false"
      :selectedColumnHeadersSortable="false"
      searchProperty="device_code"
      placeHolderForSearch="Search_device_code"
      @downloadExcel="handleDownloadExcel"
      @cell-click="handleCellClick"
      @reload="handleLoadData"
      @loadData="handleLoadData"
      @cleared="handleLoadData"
    >
    <template v-slot:device_ble_last_communication_time="{ row }">
    {{
        ftm__formatEmpty(
          row.data.ble_comm_stats !== undefined ?  adm__filterToFormatDate(row.data.ble_comm_stats.utc_last_communicated_at, "dd-MMM-yyyy HH:mm") : "--"
        )
    }}
    </template>
    <template v-slot:device_af_control_updated_time="{ row }">
    {{
        ftm__formatEmpty(
          row.data.ble_comm_stats !== undefined ? adm__filterToFormatDate(row.data.ble_comm_stats.utc_last_communicated_at, "dd-MMM-yyyy HH:mm") : "--"
        )
    }}
    </template>
    <template v-slot:device_ble_chip_type="{ row }">
    {{ getBLEChipType(row.data.settings.ble_chip_type) }}
    </template>
    <template v-slot:gw_ble_chip_type="{ row }">
    {{ row.data.ble_gateway_data !== undefined ? getBLEChipType(row.data.ble_gateway_data.settings.ble_chip_type) : '--' }}
    </template>
    <template v-slot:af_control_source_configuration="{ row }">
    {{ row.data.ble_gateway_data !== undefined && row.data.ble_gateway_data.settings.af_control_source_config !== undefined ? getSourceConfiguration(row.data.ble_gateway_data.settings.af_control_source_config) : '--' }}
    </template>
    <template v-slot:device_current_af_control_source="{ row }">
    {{ row.data.ble_metrics !== undefined && row.data.ble_metrics.af_primary_controller_status !== undefined ? getSourceConfiguration(row.data.ble_metrics.af_primary_controller_status.value) : '--'}}
    </template>
    </settings-table>
  </div>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import settingsTable from "@/components/base/settingsTable";
// import dialogForm from "@/components/superadmin/shared/dialogForm";
import { mapActions, mapGetters } from "vuex";
import XLSX from "xlsx";
import accountManagerDevicesMixin from '../../accountManager/devicesTab/accountManagerDevicesMixin';
export default {
  mixins: [errorHandlerMixin, adminDatesHandlerMixin, filtersMixin,
    accountManagerDevicesMixin("CONTAINER")],
  components: {
    settingsTable,
    // dialogForm
  },
  data: function() {
    return {
      queryData: {},
      tableData: [],
      total: 0,
      loading: false,
      componentName: "pmBLEMetrics",
    };
  },
  computed: {
    ...mapGetters("superadmin", {
      getPondMothers: "getPondMothers"
    }),
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      return "calc(100vh - 150px)";
    },

    computedComponentName: {
      get() {
        return this.componentName;
      },
      set(tabName) {
        this.componentName = tabName;
      }
    },
    columnsObject: function() {
      return [
        {
          prop: "code",
          label: "Device ID",
          required: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: "gateway_code",
          label: "GW ID",
          required: true,
          minWidth: 120
        },
        {
          prop: "ble_gateway_data.code",
          label: "BLE GW Code",
          required: true,
          minWidth: 120
        },
        {
          prop: "ble_gateway_data.settings.software_version",
          label: "Gateway version",
          required: true,
          minWidth: 200
        },
        {
          prop: "user_details.full_name",
          label: "Customer",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_gateway_data.settings.ble_chip_type",
          label: "GW BLE Chip type",
          required: true,
          minWidth: 200
        },
        {
          prop: "settings.ble_chip_type",
          label: "Device BLE Chip type",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_comm_stats.utc_last_communicated_at",
          label: "Device BLE Last communication time",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_gateway_data.settings.af_control_source_config",
          label: "AF Control Source configuration",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_metrics.disconnection_root_cause.value",
          label: "Device Disconnection root cause",
          required: true,
          minWidth: 100
        },
        {
          prop: "ble_metrics.af_primary_controller_status.value",
          label: "Device Current AF Control Source",
          required: true,
          minWidth: 100
        },
        {
          prop: "ble_comm_stats.utc_last_communicated_at",
          label: "Device AF Control Updated time",
          required: true,
          minWidth: 200
        },
        {
          prop: "sensor_values.ble_avg_rssi",
          label: "Device RSSI",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_comm_stats.avg_rssi",
          label: "GW RSSI",
          required: true,
          minWidth: 200
        },
        {
          prop: "ble_gateway_data.ble_metrics",
          label: "GW metrics",
          required: true,
          minWidth: 400
        },
        {
          prop: "ble_metrics",
          label: "PM metrics",
          required: true,
          minWidth: 400
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllDeviceDetails: "fetchAllDeviceDetails",
      clearDeviceDetails: "clearDeviceDetails",
      saveListDevicesData: "saveListDevicesData",
      saveSingleDeviceSettingsData: "saveSingleDeviceSettingsData"
    }),
    getBLEChipType(input) {
      const BLEChipTypeObject = {
        0: "No chip detected",
        1: "NINA",
        2: "ESP32-C3"
      };
      return BLEChipTypeObject[input];
    },
    getSourceConfiguration(input) {
      console.log("input--------", input);
      const SourceConfigurationObject = {
        0: "GW",
        1: "ST GW",
      };
      return SourceConfigurationObject[input];
    },
    async handleDownloadExcel() {
        this.loading = true;
        const query = {};
        query.include_ble_data = true;
        query.get_all = true
        // query.order_by = "code";
        // query.order_type = "asc";
        await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          infinite_scroll: false,
          params: query
        });
        let xlData = [];
        xlData = this.getPondMothers.reduce((acc, currentObj) => {
          const formatData = {};
          this.columnsObject.map(item => {
            let dataValue = this.getNestedData(currentObj, item.prop);
            if (item.label === 'GW BLE Chip type' || item.label === 'Device BLE Chip type') {
                dataValue = this.getBLEChipType(dataValue);
            } else if (item.label === 'AF Control Source configuration' || item.label === 'Device Current AF Control Source') {
                dataValue = this.getSourceConfiguration(dataValue);
            } else if (item.label === 'Device BLE Last communication time' || item.label === 'Device AF Control Updated time') {
                dataValue = this.ftm__formatEmpty(
                this.adm__filterToFormatDate(dataValue, "dd-MMM-yyyy HH:mm")
                );
            }
            formatData[this.$t(`${item.label}`)] = dataValue;
            // }
          });
          acc.push(formatData);
          return acc;
        }, []);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(xlData);
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, "PondMotherFilters.xlsx", {
        type: "binary",
        cellDates: true,
      });
      this.loading = false;
    },
    getNestedData(obj, path) {
    const keys = path.split('.');
    for (const key of keys) {
        obj = obj[key];
        if (!obj) return undefined;
    }
    return obj;
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryData = query;
        query.include_ble_data = true
        // query.order_by = "code";
        // query.order_type = "asc";
        const response = await this.fetchAllDeviceDetails({
          device_type: "pond_mother",
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.getPondMothers;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.refreshTableLayout();
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.$store.dispatch("superadmin/clearDeviceDetails", {
          device_type: "pond_mother"
        });
      }
    },
    handleTabClick(tab) {
      // console.log(tab);
      this.clearDeviceDetails({
        device_type: "pond_mother"
      });
    },
    handleSelectedComponent(component) {
      this.componentName = component;
    },
    async handleDeviceClick(event, { device_code, device_type }) {
      const deviceRouteStr = {
        gateway: "/admin/gateways/",
        pond_mother: "/admin/pondmothers/",
        pond_guard: "/admin/pondguards/",
        shrimp_talk: "/admin/shrimptalks/"
      };
      const responseDeviceKey = {
        gateway: "gateways",
        pond_mother: "pond_mothers",
        pond_guard: "pond_guards",
        shrimp_talk: "shrimp_talks"
      };
      const response = await this.fetchAllDeviceDetails({
        device_type: device_type,
        params: { device_code: device_code }
      });
      this.clearDeviceDetails({
        device_type: device_type
      });
      this.$router.push(
        `${deviceRouteStr[device_type]}${
          response[responseDeviceKey[device_type]][0]._id
        }`
      );
    },
    async saveSettingsInDb(value, settings = {}, rowData, key) {
      await this.$confirm(
        "These changes will effect the behaviour of the device, Are you sure?",
        "Warning",
        {
          confirmButtonText: "Save",
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
      const payload = { ...settings };
      payload[key] = value;
      try {
        this.loading = true;
        const response = await this.saveSingleDeviceSettingsData({
          device_code: rowData.code,
          payload,
          settingsType: "POND_MOTHER"
        });
        this.$notify({
          title: "Success",
          dangerouslyUseHTMLString: true,
          message: response.data.message,
          duration: 3000,
          type: "success"
        });
        await this.handleLoadData(this.queryData);
      } catch (err) {
        this.$notify({
          title: "Error",
          dangerouslyUseHTMLString: true,
          message: "Error while saving the data",
          duration: 3000,
          type: "success"
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.el-table__fixed {
  height: 732px !important;
}
.pagination-wrap {
  margin-bottom: 5px;
}
.pm-setting-table-main-container {
  //
}
// .el-table__body-wrapper {
//   // height: 510px !important;
// }
// .el-table__fixed {
//   height: 380px !important;
// }
.admin-header {
  .el-menu {
    z-index: 0 !important;
  }
}

// .el-menu {
//   margin-bottom: -37px !important;
// }
.padding-10-0 {
  padding: 4px 12px !important;
}
.pond-mother-table-sa {
  .btn-gateway-code {
    color: #606266;
    font-weight: normal;
    letter-spacing: 0.1px;
    font-size: 12px;
  }
}
</style>
