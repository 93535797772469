<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerTableSA.vue
Description: This file contains the list of the Account Manager associated with the company and add new Account by the access of the superadmin
-->
<template>
    <layout :no-aside="true" class="sales-manager-table-sa">
      <er-dialog
        width="30%"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        @open="handleOpenDialog"
        :title="dailogTitle"
        class="action-on-dealer"
        custom-class="Add_Users_To_Account_Manager"
      >
        <ValidationObserver ref="dialogListForm">
          <el-form size="large">
            <ValidationProvider
              name="Exixsting Sales Executive"
            >
            <el-form-item>
              <er-input disabled :value=" existingExecutive.first_name +
                        '\xa0\xa0' +
                        existingExecutive.last_name + '(' + existingExecutive.email + ')'">
              </er-input>
            </el-form-item>
            </ValidationProvider>
            <ValidationProvider
              name="Replace Sales Executive"
              rules="required"
              v-slot="{ errors }"
            >
              <el-form-item :error="errors[0]">
                <er-select
                  :placeholder="$t('Comn_search')"
                  :loading="userListLoading"
                  class="select-devices"
                  v-model="selectedUsers"
                  value-key="_id"
                  filterable
                  remote
                  :remote-method="fetchUsersToAssign"
                >
                  <el-option
                    v-for="item in usersList"
                    :key="item._id"
                    :label="
                      item.first_name +
                        '\xa0\xa0' +
                        item.last_name.concat('(' + item.email + ')')
                    "
                    :value="item"
                  ></el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
          </el-form>
        </ValidationObserver>
        <template slot="footer">
          <er-button
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            :loading="saveLoading"
            @click="submitUserDetails"
          ></er-button>
          <er-button
            btnType="cancel"
            :showLabel="true"
            :loading="saveLoading"
            @click="handleCloseDialog"
          ></er-button>
        </template>
      </er-dialog>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="Sales Manager" name="sales_manager">
          <div>
            <el-radio-group
              v-model="selectedStatus"
              size="mini"
              @input="handleStatusChange"
            >
              <el-radio-button label="ACTIVE" class="is-plain">
                {{ $t("Comn_active") }}
              </el-radio-button>
              <el-radio-button label="INACTIVE" class="is-plain">
                {{ $t("Comn_inactive") }}
              </el-radio-button>
              <el-radio-button label="ALL" class="is-plain">
                {{ $t("Comn_all") }}
              </el-radio-button>
            </el-radio-group>
            <create-new-customer
              user-type="SALES_MANAGER"
              :model="formAccountManager"
              :action="actionToPerform"
              :visible="actionDialogVisible"
              @close="closeActionDialog"
            ></create-new-customer>
            <settings-table
              v-loading="loading"
              element-loading-background="white"
              :tableData="tableData"
              :columns="columnsObject"
              :total="total"
              :button="true"
              :tableProps="tableProps"
              :addPadingToSearch="true"
              :columnselector_require="false"
              placeHolderForSearch="Search_username"
              search-property="user_name"
              @cell-click="handleCellClick"
              @loadData="handleLoadData"
              @reload="handleLoadData"
              @cleared="handleLoadData"
              @add-item-click="handleAddClientClick"
            >
              <template slot="add-buttton">Add Sales Manager</template>
              <template v-slot:name="{ row }">
                {{ row.data.first_name }} {{ row.data.last_name }}
              </template>
              <template v-slot:status="{ row }">
                {{ row.data.status === 'ACTIVE' ? "Active" : "Inactive" }}
              </template>
              <template v-slot:actions="{ row }">
                <el-button-group>
                  <er-button size="mini" @click="handleEditManagerClick(row.data)">
                    <span class="material-icons-round"> edit </span>
                  </er-button>
                  <er-button size="mini" icon="el-icon-refresh" @click="handleReplaceManagerClick(row.data)">
                    <!-- <span> Replace </span> -->
                  </er-button>
                </el-button-group>
              </template>
            </settings-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Sales Executive" name="sales_executive">
          <div>
            <el-radio-group
              v-model="selectedExecutiveStatus"
              size="mini"
              @input="handleExecutiveTableStatusChange"
            >
              <el-radio-button label="ACTIVE" class="is-plain">
                {{ $t("Comn_active") }}
              </el-radio-button>
              <el-radio-button label="INACTIVE" class="is-plain">
                {{ $t("Comn_inactive") }}
              </el-radio-button>
              <el-radio-button label="ALL" class="is-plain">
                {{ $t("Comn_all") }}
              </el-radio-button>
            </el-radio-group>
            <create-new-customer
              user-type="SALES_EXECUTIVE"
              :model="formAccountManager"
              :action="actionToPerform"
              :visible="actionDialogVisible"
              @close="skrtCloseActionDialog"
            ></create-new-customer>
            <settings-table
              v-loading="loading"
              element-loading-background="white"
              :tableData="executiveTableData"
              :columns="skrettingColumnsObject"
              :total="skrtTotal"
              :button="true"
              :tableProps="tableProps"
              :addPadingToSearch="true"
              :columnselector_require="false"
              placeHolderForSearch="Search_username"
              search-property="user_name"
              @loadData="handleLoadExicutivesData"
              @reload="handleLoadExicutivesData"
              @cleared="handleLoadExicutivesData"
              @add-item-click="handleAddExecutiveClick"
            >
              <template slot="add-buttton">Add Sales Executive</template>
              <template v-slot:name="{ row }">
                {{ row.data.first_name }} {{ row.data.last_name }}
              </template>
              <template v-slot:status="{ row }">
                {{ row.data.status === 'ACTIVE' ? "Active" : "Inactive" }}
              </template>
              <template v-slot:actions="{ row }">
                <el-button-group>
                  <er-button size="mini" @click="handleEditExecutiveClick(row.data)">
                    <span class="material-icons-round"> edit </span>
                  </er-button>
                  <er-button size="mini" icon="el-icon-refresh" @click="handleReplaceExecutiveClick(row.data)">
                    <!-- <span> Replace </span> -->
                  </er-button>
                </el-button-group>
              </template>
            </settings-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </layout>
  </template>

  <script>
  import errorHandlerMixin from "@/mixins/errorHandlerMixin";
  import settingsTable from "@/components/base/settingsTable";
  import createNewCustomer from "@/components/superadmin/shared/createNewCustomer";
  import layout from "@/components/superadmin/shared/layout";
  import { mapActions, mapGetters } from "vuex";
  import { extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import User from "@/model/user.js";
  extend("required", {
    ...required,
    message: "{_field_} is required"
  });
  export default {
    mixins: [errorHandlerMixin],
    data: function () {
      return {
        tableData: [],
        executiveTableData: [],
        formAccountManager: new User(),
        countries: {},
        user: {},
        formName: "signUpForm",
        countryCode: "",
        loading: false,
        ehm__errMessagesObject: new User(),
        hasError: false,
        total: 0,
        skrtTotal: 0,
        clientActionDialogTitle: "Add",
        dialogBoxLoading: false,
        saveLoading: false,
        actionDialogVisible: false,
        dialogVisible: false,
        selectedClient: {},
        action: "ADD_CLIENT",
        dailogTitle: '',
        queryParams: { status: "ACTIVE" },
        skrtQueryParams: { status: "ACTIVE" },
        userSearchQuery: {},
        existingExecutive: {},
        usersList: [],
        userListLoading: false,
        userQuery: {
          include: ["first_name", "last_name", "email"],
          un_assigned_only: true
        },
        actionToPerform: "ADD",
        tableProps: {
          size: "small",
          height: "calc(100vh - 210px)",
          defaultSort: {
            prop: "first_name",
            order: "ascending"
          }
        },
        activeTab: "sales_manager",
        selectedStatus: "ACTIVE",
        selectedExecutiveStatus: "ACTIVE",
      };
    },
    components: {
      settingsTable,
      layout,
      createNewCustomer
    },
    computed: {
      ...mapGetters("superadmin", {
        getUsers: "getUsers",
        getCountries: "getCountries"
      }),
      userFormLabels() {
        return [
          {
            label: this.$t("Usrs_first_name"),
            placeholder: this.$t("Usrs_enter_first_name")
          },
          {
            label: this.$t("Usrs_last_name"),
            placeholder: this.$t("Usrs_enter_last_name")
          },
          {
            label: this.$t("Comn_email_address"),
            placeholder: this.$t("Usrs_enter_email_addr")
          },
          {
            label: this.$t("Comn_country"),
            placeholder: this.$t("Usrs_enter_country")
          },
          {
            label: this.$t("Comn_mobile_num"),
            placeholder: this.$t("Usrs_enter_mobile_num")
          },
          {
            label: this.$t("Usrs_pwd"),
            placeholder: this.$t("Usrs_enter_pwd")
          },
          {
            label: this.$t("Comn_confirm_pwd"),
            placeholder: this.$t("Usrs_enter_confirm_pwd")
          }
        ];
      },
      columnsObject: function () {
        return [
          {
            label: "Name",
            prop: "first_name",
            required: true,
            sortable: true
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true
          },
          {
            prop: "country.name",
            label: "Country",
            required: true,
            sortable: true
          },
          {
            prop: "phone",
            label: "Mobile Number",
            required: true,
            sortable: true
          },
          {
            prop: "status",
            label: "Status",
            required: true,
            sortable: true
          },
          {
            label: "Actions",
            required: true
          }
        ];
      },
      skrettingColumnsObject: function () {
        return [
          {
            label: "Name",
            prop: "first_name",
            required: true,
            sortable: true
          },
          {
            prop: "email",
            label: "Email Address",
            required: true,
            sortable: true
          },
          {
            label: "Sales Manager",
            prop: "allowed_account_managers[0].full_name",
            required: true,
            sortable: true
          },
          {
            prop: "phone",
            label: "Mobile Number",
            required: true,
            sortable: true
          },
          {
            prop: "status",
            label: "Status",
            required: true,
            sortable: true
          },
          {
            label: "Actions",
            required: true
          }
        ];
      }
    },
    methods: {
      ...mapActions("superadmin/salesManager", {
        fetchAllSalesManagers: "fetchAllSalesManagers",
        fetchAllSalesExecutiveUsers: "fetchAllSalesExecutiveUsers",
        fetchCountryList: "fetchCountryList",
        replaceSalesExicutive: "replaceSalesExicutive",
        replaceSalesManager: "replaceSalesManager"
      }),
      getNewClient() {
        return {
          title: "",
          users: []
        };
      },
      handleStatusChange() {
        if (this.selectedStatus === "ALL") {
          delete this.queryParams.status
          this.handleLoadData(this.queryParams);
        } else {
          this.queryParams.status = this.selectedStatus;
          this.handleLoadData(this.queryParams);
        }
        // if (this.selectedStatus === "ALL") {
        //     this.tableData = this.$store.getters["superadmin/salesManager/getSalesManagers"];
        // } else {
        //   this.tableData = this.$store.getters["superadmin/salesManager/getSalesManagers"]
        //     .filter((x) => x.status === this.selectedStatus);
        // }
      },
      handleExecutiveTableStatusChange() {
        if (this.selectedExecutiveStatus === "ALL") {
          delete this.skrtQueryParams.status
          this.handleLoadExicutivesData(this.skrtQueryParams);
        } else {
          this.skrtQueryParams.status = this.selectedExecutiveStatus;
          this.handleLoadExicutivesData(this.skrtQueryParams);
        }
        // if (this.selectedExecutiveStatus === "ALL") {
        //     this.executiveTableData = this.$store.getters["superadmin/salesManager/getSalesExecutiveUserData"];
        // } else {
        //   this.executiveTableData = this.$store.getters["superadmin/salesManager/getSalesExecutiveUserData"]
        //     .filter((x) => x.status === this.selectedExecutiveStatus);
        // }
      },
      showActionDialog(value) {
        this.actionDialogVisible = value;
      },
      async closeActionDialog(message) {
        this.showActionDialog(false);
        if (message === "user_created") {
          await this.handleLoadData(this.queryParams);
        }
      },
      async skrtCloseActionDialog(message) {
        this.showActionDialog(false);
        if (message === "user_created") {
          await this.handleLoadExicutivesData(this.skrtQueryParams);
        }
      },
      handleTabClick(tab) {},
      async handleSelectedComponent(component) {
        this.tabName = component;
        // await this.handleLoadData(this.queryParams);
      },
      // handleAddUsersToClient(clientDetails) {
      //   this.action = "ADD_CLIENT_USERS";
      //   this.clientActionDialogTitle = "Add Client Users";
      //   this.actionDialogVisible = true;
      // },

      async handleLoadData(query) {
        try {
          this.loading = true;
          this.queryParams = { ...this.queryParams, ...query };
          const response = await this.fetchAllSalesManagers({
            infinite_scroll: false,
            params: this.queryParams
          });
          // if (this.selectedStatus === "ALL") {
            this.tableData = this.$store.getters["superadmin/salesManager/getSalesManagers"];
          // } else {
          //   this.tableData = this.$store.getters["superadmin/salesManager/getSalesManagers"]
          //     .filter((x) => x.status === this.selectedStatus);
          // }
          this.total = response.total;
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },
      async handleLoadExicutivesData(query) {
        try {
          this.loading = true;
          this.skrtQueryParams = { ...this.skrtQueryParams, ...query };
          const response = await this.fetchAllSalesExecutiveUsers({
            infinite_scroll: false,
            params: this.skrtQueryParams
          });
          // if (this.selectedExecutiveStatus === "ALL") {
            this.executiveTableData = this.$store.getters["superadmin/salesManager/getSalesExecutiveUserData"];
          // } else {
          //   this.executiveTableData = this.$store.getters["superadmin/salesManager/getSalesExecutiveUserData"]
          //     .filter((x) => x.status === this.selectedExecutiveStatus);
          // }
          this.skrtTotal = response.total;
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.loading = false;
        }
      },
      handleCellClick(row, column, cell, event) {
        if (column.label === this.columnsObject[0].label) {
          this.$router.push({
            name: "salesmanagerdetails",
            params: { salesmanager_id: row._id }
          });
        }
      },
      handleAddClientClick() {
        this.actionToPerform = "ADD";
        this.formAccountManager = new User();
        this.clientActionDialogTitle = "Add Sales Manager";
        this.actionDialogVisible = true;
      },
      handleAddExecutiveClick() {
        this.actionToPerform = "ADD";
        this.formAccountManager = new User();
        this.clientActionDialogTitle = "Add Sales Executive";
        this.actionDialogVisible = true;
      },
      handleEditManagerClick(salesManagerDetails) {
        this.actionToPerform = "EDIT";
        this.formAccountManager = salesManagerDetails;
        this.clientActionDialogTitle = "Edit Sales Manager";
        this.actionDialogVisible = true;
      },
      handleEditExecutiveClick(skrtUserDetails) {
        this.actionToPerform = "EDIT";
        this.formAccountManager = skrtUserDetails;
        this.clientActionDialogTitle = "Edit Sales Executive";
        this.actionDialogVisible = true;
      },
      handleReplaceExecutiveClick(data) {
        this.dialogVisible = true;
        this.existingExecutive = data
        this.dailogTitle = "Replace Executive"
      },
      handleReplaceManagerClick(data) {
        this.dialogVisible = true;
        this.existingExecutive = data
        this.dailogTitle = "Replace Manager"
      },
      handleCloseDialog() {
        this.dialogVisible = false;
      },
      handleOpenDialog(event) {
        this.usersList = [];
        this.selectedUsers = "";
        this.$nextTick(() => {
          this.$refs.dialogListForm.reset();
        });
      },
      async fetchUsersToAssign(queryString) {
        if (queryString === "") return;
        try {
          this.userListLoading = true;
          this.userSearchQuery.user_name = queryString;
          this.userSearchQuery.status = 'ACTIVE';
          this.userSearchQuery.email_not_verified_users_only = false;
          if (this.activeTab === 'sales_manager') {
            await this.fetchAllSalesManagers({
              infinite_scroll: false,
              params: this.userSearchQuery
            });
            this.usersList = this.$store.getters["superadmin/salesManager/getSalesManagers"].filter(user => user._id !== this.existingExecutive._id);
          } else {
            await this.fetchAllSalesExecutiveUsers({
              infinite_scroll: false,
              params: this.userSearchQuery
            });
            this.usersList = this.$store.getters["superadmin/salesManager/getSalesExecutiveUserData"].filter(user => user._id !== this.existingExecutive._id);
          }
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.userListLoading = false;
        }
      },
      async submitUserDetails() {
        const response = await this.$refs.dialogListForm.validate();
        if (!response) return;
        try {
          this.saveLoading = true;
          const payload = {
            existing_user_id: this.existingExecutive._id,
            replaced_user_id: this.selectedUsers._id
          };
          if (this.activeTab === 'sales_manager') {
            await this.replaceSalesManager(payload);
          } else {
            await this.replaceSalesExicutive(payload);
          }
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: "Replaced Successfully",
            duration: 5000,
            type: "success"
          });
        } catch (error) {
          this.ehm__errorMessages(error, true);
        } finally {
          this.dialogVisible = false;
          this.saveLoading = false;
          if (this.activeTab === 'sales_manager') {
            await this.handleLoadData(this.queryParams);
          } else {
            await this.handleLoadExicutivesData(this.skrtQueryParams);
          }
        }
      }
    }
    //   async initComponent() {
    //     await this.fetchCountryList();
    //   }
    // },

    // async mounted() {
    //   await this.initComponent();
    // }
  };
  </script>

  <style lang="scss">
  .sales-manager-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
    .settings-table .settings-table-scroll {
      position: relative;
      @include responsiveProperty(height, 80vh, 84vh, 87vh);
    }
    .el-icon-refresh {
      font-size: 15px;
      font-weight: 600;
    }
  }
  </style>
