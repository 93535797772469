<template>
    <el-row class="inquiry-log" style="padding:10px">
      <el-row type="flex" class="inquiry-log-header">
        <el-col :lg="19">
          <h4>Inquiry Log</h4>
        </el-col>
        <el-col :lg="4" style="text-align: right;">
          <er-date-picker
            v-model="dateRangeSelected"
            value-format="yyyy-MM-dd"
            :timeZoneString="getUserTimeZoneString"
            :format="upm__getFormatDateString"
            type="daterange"
            :clearable="false"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          ></er-date-picker>
        </el-col>
      </el-row>
      <settings-table
        ref="erDataTables"
        v-loading="loading"
        element-loading-background="white"
        :tableData="tableDataArray"
        :tableProps="elTableProps"
        :total="total"
        :button="false"
        :searchable="false"
        :columns="columnsFilterByTab"
        :columnselector_require="false"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
      >
      <!-- :show-overflow-tooltip="column.showOverflowTooltip" -->
        <template v-slot:customer_name="{row}">
          <er-text-tag
            :text-to-scroll="`${row.data.name}`"
            :threshold-characters="20"
          >
          {{row.data.name}}
          </er-text-tag>
        </template>
        <template v-slot:email>
        </template>
        <template v-slot:mobile>
        </template>
        <template v-slot:message="{row}">
          <span class="hiding-extra-text">{{row.data.message}}</span>
        </template>
        <template v-slot:requested_on="{ row }">
            {{ dateFormat(row.data.utc_added_date) }}
        </template>
        <template v-slot:reply="{ row }">
            <er-button
                type="text"
                class="hiding-extra-reply-text"
                @click="handleReplyPopup(row.data)"
                >{{row.data.replies.length ? row.data.replies[0].reply : "None"}}
            </er-button>
        </template>
        <template v-slot:replied_on="{ row }">
            {{ row.data.replies.length ? dateFormat(row.data.replies[0].utc_date) : "--" }}
        </template>
      </settings-table>
      <el-dialog
        custom-class="dialog-body-width-50 dialog-body-y-20"
        title="Reply"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
        @close="handleDialogCancel">
        <div class="msgbox-css" >
          <p v-if="messageData.length==0"> No Data Available</p>
          <ol v-for="(msg, index) in messageData" :key="index" style="padding-bottom: 10px;">
            <p>Date : {{dateFormat(msg.utc_date)}}</p>
            <p>Message : {{msg.reply}}</p>
          </ol>
        </div>
        <el-form :model="inquiry_form">
          <el-form-item>
              <el-date-picker
                size="mini"
                v-model="inquiry_form.form_date"
                type="datetime"
                format="dd MMM yy HH:mm"
                value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
                placeholder="Pick a day"
                :picker-options="fromDatePickerOptions"
                disabled="true"
                >
                <!--:format="upm__getFormatDateString" -->
              </el-date-picker>
          </el-form-item>
          <el-form-item>
              <el-input
                type="textarea"
                :rows="2"
                size="mini"
                maxlength="300"
                placeholder="Message to reply..."
                v-model="inquiry_form.reply"
                @input="handleTextareaChange"
                autocomplete="off">
              </el-input>
              <div v-if="error_msg" class="error_msg">Reply field is required</div>
          </el-form-item>
        </el-form>
        <template slot="footer">
           <er-button
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              @click="submitReplyData"
            ></er-button>
            <er-button
              btnType="cancel"
              :showLabel="true"
              @click="handleDialogCancel"
            ></er-button>
        </template>
      </el-dialog>
    </el-row>
</template>

<script>
import filtersMixin from "@/mixins/filtersMixin.js";
import settingsTable from "@/components/base/settingsTable";
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import adminDatesHandlerMixin from "@/mixins/adminDatesHandlerMixin";
import moment from "moment";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    adminDatesHandlerMixin
  ],
  components: {
    settingsTable,
  },
  data: function () {
    return {
    total: 0,
    loading: false,
    inquiry_form: {
      form_date: '',
      reply: ''
    },
    tabName: "verified",
    messageData: [],
    dialogFormVisible: false,
    tableDataArray: [],
    tableProps: {
        size: "small",
        height: "calc(100vh - var(--table-gutter))"
    },
    inquiry_log_id: '',
    last_communication_date: '',
    dateRangeSelected: [],
    fromDatePickerOptions: {
       disabledDate: this.disabledDate,
    },
    error_msg: false
    }
  },
  created() {
      this.dateRangeSelected = this.defaultDates();
  },
  computed: {
    ...mapGetters("superadmin/inquiryLog", {
      getInquiryLogData: "getInquiryLogData",
    }),
    tableColumns() {
        return [
            {
              prop: "name",
              label: "Customer Name",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 110,
              fixed: "fixed"
            },
            {
              prop: "email",
              label: "Email Id",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 120,
            },
            {
              prop: "mobile",
              label: "Mobile Number",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 80,
            },
            {
              prop: "message",
              label: "Message",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 200,
              showOverflowTooltip: true,
            },
            {
              prop: "utc_added_date",
              label: "Requested On",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 100,
            },
            {
              prop: "reply",
              label: "Reply",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 150,
            },
            {
              prop: "utc_date",
              label: "Replied On",
              required: true,
              sortable: true,
              tab: ["verified", "unverified"],
              minWidth: 100,
            }
        ]
    },
    pickerOptions: function() {
      return {};
    },
    elTableProps() {
      return {
        headerCellClassName: this.headerCellName,
        height: this.getRespTableHeight,
        defaultSort: {
          prop: "title",
          order: "ascending"
        }
      };
    },
    getRespTableHeight() {
      if (window.innerWidth < 2560 && window.innerWidth > 1537) {
        return "calc(100vh - 210px)";
      } else if (window.innerWidth > 1535 && window.innerWidth < 1920) {
        return "calc(100vh - 200px)";
      } else {
        return "calc(100vh - 189px)";
      }
    },
    columnsFilterByTab: function () {
      this.refreshTableLayout();
      return this.tableColumns.filter((x) => x.tab.includes(this.tabName));
    },
  },
  methods: {
    ...mapActions("superadmin/inquiryLog", {
      fetchInquiryLog: "fetchInquiryLog",
      addReplyToInquiryLog: "addReplyToInquiryLog"
    }),
    dateFormat(date) {
        if (
          date &&
          this.dhm__dateUtilsLib.isValid(new Date(date))
        ) {
          return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
            date,
            this.upm__getFormatDateAndTimeString,
            this.getUserTimeZoneString,
            this.dhm__getLocaleObj
          );
        } else {
          return "--";
        }
    },
    defaultDates() {
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__dateUtilsLib.utcToZonedTime(
          this.dhm__dateUtilsLib.add(new Date(), { days: 0 }),
          this.getUserTimeZoneString
        )
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.subtract(new Date(), {
          days: 60
        })
      );
      return [startDate, endDate];
    },
    handleReplyPopup(data) {
        this.dialogFormVisible = true;
        this.messageData = data.replies;
        const currentDate = this.adm__dateUtilsLib.formatDate(
                              new Date(),
                              this.adm__dateUtilsLib.isoFormatString
                            );
        this.inquiry_form.form_date = currentDate;
        this.last_communication_date = new Date(this.messageData[0] ? this.messageData[0]?.utc_date : data?.utc_added_date);

        this.inquiry_log_id = data._id;
        // this.fromDatePickerOptions();
    },
    handleDateChange(data) {
      this.loading = true;
      const paramsData = {
          ...this.queryParams,
          order_by: "created_at",
          order_type: "asc",
          from_date: data[0] + "T00:00:00.000Z",
          to_date: data[1] + "T23:59:59.999Z"
        }
        this.dateRangeSelected = data;
      this.GetFilteredInquiryData(paramsData)
    },
    async handleLoadData(query) {
        this.loading = true;
        this.queryParams = query;
        const paramsData = {
          ...query,
          order_by: "created_at",
          order_type: "asc",
          from_date: moment(this.dateRangeSelected[0]).format("YYYY-MM-DD") + "T00:00:00.000Z",
          to_date: moment(this.dateRangeSelected[1]).format("YYYY-MM-DD") + "T23:59:59.999Z"
        }
      this.GetFilteredInquiryData(paramsData)
    },
    async GetFilteredInquiryData(paramsData) {
      try {
        const response = await this.fetchInquiryLog({
          params: paramsData,
        });
        this.tableDataArray = this.getInquiryLogData;
        this.total = response.data.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    refreshTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.erDataTables) {
          this.$refs.erDataTables.$refs.dataTable.$refs.elTable.doLayout();
        }
      });
    },
    disabledDate(date) {
      return moment(date).format("YYYY-MM-DD") + "T23:59:59.999Z" < moment(this.last_communication_date).format("YYYY-MM-DD") + "T00:00:00.000Z";
    },
    async submitReplyData() {
      if (this.inquiry_form.reply !== '') {
        this.error_msg = false;
        const payload = {
                        inquiry_log_id: this.inquiry_log_id,
                        date: this.inquiry_form.form_date,
                        reply: this.inquiry_form.reply
                      }
        await this.addReplyToInquiryLog(payload);
        await this.handleLoadData(this.queryParams);
        this.dialogFormVisible = false
        this.inquiry_form = {
          form_date: '',
          reply: ''
        }
      } else {
        this.error_msg = true;
      }
    },
    handleDialogCancel() {
      this.dialogFormVisible = false
      this.inquiry_form = {
        form_date: '',
        reply: ''
      }
      this.error_msg = false;
    },
    handleTextareaChange(data) {
      if (data.trim() === '') {
        this.error_msg = true;
      } else {
        this.error_msg = false;
      }
    },
  }
}
</script>

<style lang="scss">
  .dialog-body-y-20 {
    .el-dialog__body {
      padding: 0px 20px !important;
    }
  }
  .dialog-body-width-50 {
        border-radius: 15px;
        width: 50% !important;
  }
  .inquiry-log {
  .settings-table .padding-10-0 .toolbar-layout {
    .er-button {
      z-index: 99 !important;
    }
  }
  .error_msg {
    font-size: 10px;
    line-height: 1.5;
    color: #ee1c1c;
  }
}
.el-time-spinner {
    .el-time-spinner__wrapper:first-child {
      .el-scrollbar__thumb {
        height: 40px;
      }
    }
    .el-time-spinner__wrapper:nth-child(2) {
      .el-scrollbar__thumb {
        height: 17px;
      }
    }
  }
</style>
<style lang="scss" scoped>
.msgbox-css{
  overflow: auto;
    height: 100px;
    margin-bottom: 10px;
    border: 1px solid #c9cbcc;
}
p{
  font-size: 11px;
  padding: 5px 10px 0px 10px;
}
.inquiry-log-header{
    padding: 10px;
    margin-bottom: -41px;
    z-index: 10;
    margin-right: -4px;
}
.hiding-extra-text{
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // max-width: 150px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.hiding-extra-reply-text{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
}
</style>
