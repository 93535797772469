<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: createNewCustomer.vue
Description:This is form where new Customer details are filled and submitted by admin
-->
<template>
  <er-dialog
    :title="getDialogTitle"
    :visible="visible"
    :modal-append-to-body="false"
    :width="user_type === 'superAdmin'? '70%' : '40%'"
    :close-on-click-modal="false"
    @open="resetForm"
    @close="handleDialogClose"
    class="create-new-customer-sa"
    custom-class="create_new_customer_dialog"
  >
    <el-row>
      <el-col :span="user_type === 'superAdmin'? 12 : 24">
        <el-form
          :ref="formName"
          :model="user"
          size="mini"
          v-loading="loading"
          element-loading-background="white"
          :element-loading-text="$t('Comn_auth_loading')"
        >
          <el-form-item
            prop="first_name"
            class="name-element"
            label="First Name"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)"
            >
            <el-input
              v-model="user.first_name"
              :disabled="isEditCustomerForm"
              :placeholder="userFormLabels[0].placeholder"
              :maxlength="userFieldLengths.first_name"
            >
            </el-input>
          </el-form-item>

          <el-form-item
            prop="last_name"
            class="name-element"
            label="Last Name"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)"
          >
            <el-input
              v-model="user.last_name"
              :disabled="isEditCustomerForm"
              :placeholder="userFormLabels[1].placeholder"
              :maxlength="userFieldLengths.last_name"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="userType !== 'SKRETTING_TECHNICIAN'"
            prop="country"
            label="Country"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.country.name)"
          >
            <er-select
              v-model="user.country"
              value-key="code"
              autocomplete="new-country"
              filterable
              :disabled="isEditCustomerForm"
              :placeholder="userFormLabels[3].placeholder"
            >
              <template slot="default">
                <el-option
                  v-for="(name, code) in countries"
                  :key="code"
                  :label="name"
                  :value="{ name, code }"
                ></el-option>
              </template>
            </er-select>
          </el-form-item>
          <el-form-item
            prop="email"
            label="Email Address"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
          >
            <el-input
              v-model="user.email"
              :disabled="action === 'EDIT' || disabledCommFieldsUntilReqFilledForCustomer"
              :placeholder="userFormLabels[2].placeholder"
              :maxlength="userFieldLengths.email"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="userType !== 'CUSTOMER'"
            prop="phone"
            label="Mobile Number"
            :disabled="user-type==='CUSTOMER' && action === 'EDIT'"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)"
          >
            <el-input
              class="country-select input-box"
              v-model="user.phone"
              :placeholder="userFormLabels[4].placeholder"
              autocomplete="off"
              :maxlength="userFieldLengths.mobile"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-else
            prop="mobile"
            label="Mobile Number"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.mobile)"
          >
            <el-input
              class="country-select input-box"
              v-model="user.mobile"
              :placeholder="userFormLabels[4].placeholder"
              autocomplete="off"
              :disabled=" isEditCustomerForm || disabledCommFieldsUntilReqFilledForCustomer"
              :maxlength="userFieldLengths.mobile"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            v-if="action === 'ADD' && userType !== 'CUSTOMER'"
            prop="password"
            label="Password"
            :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)"
          >
            <er-input
              v-model="user.password"
              :placeholder="userFormLabels[5].placeholder"
              show-password
              autocomplete="new-password"
            >
            </er-input>
          </el-form-item>
          <el-form-item
            v-if="action === 'ADD' && userType !== 'CUSTOMER'"
            prop="confirm_password"
            label="Confirm Password"
            :error="
              aekmm__castErrorKeyToLang(ehm__errMessagesObject.confirm_password)
            "
          >
            <er-input
              v-model="user.confirm_password"
              :placeholder="userFormLabels[6].placeholder"
              @keyup.native.enter="submitForm"
              show-password
            >
            </er-input>
          </el-form-item>
          <el-form-item
            v-if="(userType === 'ACCOUNTMANAGER' || userType === 'SALES_MANAGER' || userType === 'SALES_EXECUTIVE') && action === 'EDIT'"
            prop="status"
            label="Status"
          >
          <er-select
            v-model="user.status"
            placeholder="Select Status"
            @change="handleChangeInStatus"
          >
            <template v-for="(value, index) in this.statusData">
              <el-option
                :key="index"
                :label="value.label"
                :value="value.key"
              ></el-option>
            </template>
          </er-select>
          </el-form-item>
          <el-form-item
            v-if="(userType === 'SALES_MANAGER' || userType === 'SALES_EXECUTIVE')  && action !== 'EDIT'"
            label="Account Manager"
          >
          <el-checkbox v-model="isAccountManagerAccesschecked"></el-checkbox>
          </el-form-item>
          <el-form-item
  v-if="userType == 'CUSTOMER'"
  prop="tags"
  label="Tags"
  :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.tags)"
>
  <er-select
    v-model="user.tags"
    value-key="code"
    filterable
    :placeholder="userFormLabels[7].placeholder"
  >
    <template slot="default">
      <el-option
        v-for="item in this.tags"
        :key="item.code"
        :label="item.name"
        :value="item.name"
      ></el-option>
    </template>
  </er-select>
</el-form-item>
<el-form-item
  v-if="userType == 'CUSTOMER'"
  prop="allowed_account_managers"
  label="Account Managers"
  :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.allowed_account_managers)"
>
  <el-select
    v-model="user.allowed_account_managers"
    multiple
    filterable
    remote
    reserve-keyword
    :placeholder="userFormLabels[8].placeholder"
    :loading="loading"
  >
    <el-option
      v-for="manager in accountManagers"
      :key="manager._id"
      :label="`${manager.first_name} ${manager.last_name}`"
      :value="manager._id"
    ></el-option>
  </el-select>
</el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-row type="flex" justify="space-between" align="middle" style="width: 100%; flex-wrap: wrap;">
        <el-tag
            v-if="!isEditCustomerForm && isIndianCustomer"
            type="warning"
            >
            <i class="el-icon-warning-outline"></i>
          {{ getWarningByCountry }}
          </el-tag> &nbsp;
        <el-row type="flex" justify="end" align="middle">
          <er-button
            size="mini"
            btnType="save"
            :showLabel="true"
            :showIcon="true"
            :loading="loading"
            @click="submitForm"
          >
          </er-button>
          <er-button
            size="mini"
            btnType="cancel"
            :showLabel="true"
            @click="handleDialogClose"
          >
          </er-button>
        </el-row>
      </el-row>
    </template>
  </er-dialog>
</template>
<script>
import User from "@/model/user.js";
import { mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import fieldLengths from '../../../constants/fieldLengths';
import localStore from "@/utils/localStore";

export default {

  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    authErrorKeyMapMixin,
    errorKeyMapMixin
  ],
  props: ["visible", "userType", "action", "model"],
  data: function() {
    return {
      userFieldLengths: fieldLengths,
      user: new User(),
      countries: {},
      formName: "signUpForm",
      countryCode: "",
      loading: false,
      isAccountManagerAccesschecked: false,
      ehm__errMessagesObject: new User(),
      hasError: false,
      userTypeMap: {
        CUSTOMER: { label: "Customer" },
        DEALER: { label: "Dealer" },
        ACCOUNTMANAGER: { label: "Account Manager" },
        SKRETTING_TECHNICIAN: { label: "Skretting Technician" },
        SALES_MANAGER: { label: "Sales Manager" },
        SALES_EXECUTIVE: { label: "Sales Executive" }
      },
      ekmm__backndfieldToFieldNameMap: {
        email: "Comn_email_address",
        password: "Comn_password",
        first_name: "Usrs_first_name",
        last_name: "Usrs_last_name",
        confirm_password: "Comn_confirm_pwd"
      },
      ekmm__backndfieldToInvalidMap: {
        email: {
          EMAIL_ALREADY_EXISTS: "Usrs_email_already_exists",
          ACCOUNT_ALREADY_TAKEN: "Usrs_email_already_exists"
        },

        first_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        last_name: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Min 3 characters Required"
        },
        password: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Password Should contain min 6 characters"
        },
        confirm_password: {
          VALUES_SHOULD_MATCH: "Both password and confirm password must match"
        }
      },
      user_type: 'user',
      userTypeData: [{ label: "User", value: "user" },
                      { label: "Super Admin", value: "superAdmin" }],
      permissionToTabs: [{ label: "Customer", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Devices", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "PondMother", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "Gateway", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "PondGuard", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "ShrimpTalk", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "FeedBlower", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "ShrimpSnap", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "sub" },
                          { label: "Client", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Dealer", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Account Manager", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Activity Log", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Help", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" },
                          { label: "Inquiry Log", permissions: [{ key: "view", checked: true }, { key: "edit", checked: false }], tabType: "main" }
                          ],
       isIndeterminate: true,
       checkAll: false,
       checkedTabs: [],
       statusData: [
        { key: "ACTIVE", label: "Active" },
        { key: "INACTIVE", label: "Inactive" },
      ],
      accountManagers: [],
      tags: [
          { name: '-', code: '-' },
          { name: 'IN-SK360+', code: 'IN-SK360+' },
          { name: 'EC-SK360+', code: 'EC-SK360+' },
          { name: 'MX-SK360+', code: 'MX-SK360+' }
        ]
    };
  },
  computed: {
    getWarningByCountry() {
      if (this.isIndianCustomer) {
        return "Email Address or Mobile Number is required";
      }
      return "Email Address is required";
    },
    isIndianCustomer() {
      return this.user.country.name === 'india' && this.userType === 'CUSTOMER';
    },
    isEditCustomerForm() {
      return this.userType === 'CUSTOMER' && this.action === 'EDIT'
    },
    getDialogTitle() {
      const userName = this.userTypeMap[this.userType].label;
      return this.action === "ADD" ? `Create ${userName}` : `Edit ${userName}`;
    },
    getTimeZones() {
      return this.$store.getters["user/getTimeZones"];
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        },
        {
        label: this.$t("Usrs_tags"),
        placeholder: this.$t("Usrs_enter_tags")
        },
        {
        label: this.$t("Account_manager"),
        placeholder: this.$t("Usrs_select_account_manager")
        }
      ];
    },
    disabledCommFieldsUntilReqFilledForCustomer() {
      if (this.userType === "CUSTOMER") {
        return this.user.country.name === "";
      }
      return false;
    },
    aekmm__keyToInvalidTranslations: function() {
      if (this.userType === "CUSTOMER") {
        return {
        first_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        last_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_3_char_for_field",
          VALUE_HAVE_MAX_CHAR: "max_24_char_for_field"
        },
        email: {
          INVALID_VALUE: "email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "max_254_char_for_field",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_char_for_field"
        },
        login_id: {
          INVALID_VALUE: "Usrs_email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists",
          VALUE_HAVE_MAX_CHAR: "Usrs_email_in_valid_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_email_in_valid_format"
        },
        password: {
          INVALID_VALUE: "Usrs_pwd_wrng_frmt",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          INVALID_PASSWORD: "Usrs_pwd_wrng_frmt"
        },

        confirm_password: {
          INVALID_VALUE: "Usrs_pwd_cnpwd_valida",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          VALUES_SHOULD_MATCH:
            "both_the_password_and_confirm_password_must_match",
          PASSWORD_CONFIRM_PASSWORD_NOT_SAME:
            "both_the_password_and_confirm_password_must_match"
        },
        country: { INVALID_VALUE: "Comn_country" },
        phone: { INVALID_VALUE: "Comn_mobile_num_valid" },
        mobile: { INVALID_VALUE: (this.isIndianCustomer ? "Comn_mobile_num_valid" : "Comn_phone_num_valid") },
        timezone: { INVALID_VALUE: "Comn_timezone_not_valid" },
        otp: {
          VALUE_DO_NOT_HAVE_MIN_CHAR: "comn_otp_error_msg",
          INVALID_VALUE: "invalid-otp"
        }
      };
      }
      return {
        first_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_character_for_field",
          VALUE_HAVE_MAX_CHAR: "max_character_for_field"
        },
        last_name: {
          INVALID_VALUE: "Usrs_first_name_wrng_format",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "min_character_for_field",
          VALUE_HAVE_MAX_CHAR: "max_character_for_field"
        },
        email: {
          INVALID_VALUE: "email_in_valid_format",
          INVALID_USER_DATA: "Usrs_email_already_exists"
        },
        password: {
          INVALID_VALUE: "Usrs_pwd_wrng_frmt",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          INVALID_PASSWORD: "Usrs_pwd_wrng_frmt"
        },
        confirm_password: {
          INVALID_VALUE: "Usrs_pwd_cnpwd_valida",
          VALUE_DO_NOT_HAVE_MIN_CHAR: "Usrs_pwd_wrng_frmt",
          VALUES_SHOULD_MATCH:
            "both_the_password_and_confirm_password_must_match",
          PASSWORD_CONFIRM_PASSWORD_NOT_SAME:
            "both_the_password_and_confirm_password_must_match"
        },
        country: { INVALID_VALUE: "Comn_country" },
        phone: { INVALID_VALUE: "Comn_phone_num_valid" },
        timezone: { INVALID_VALUE: "Comn_timezone_not_valid" }
      };
    }
  },
  async mounted() {
    try {
      await this.fetchCountryList();
      this.countries = this.$store.getters["auth/getCountries"];
      if (this.userType === 'CUSTOMER') {
        await this.fetchAllAccountManagers({ infinite_scroll: false, params: { get_all: true } });
      this.accountManagers = this.$store.getters["superadmin/getAccountManagers"]
        }
    } catch (err) {
      this.ehm__errorMessages(err, false);
    }
  },
  methods: {
    ...mapActions("auth", {
      signUp: "signUp",
      fetchCountryList: "fetchCountryList",
      AddDealer: "AddDealer",
      EditDealer: "EditDealer",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager"
    }),
    ...mapActions("superadmin", {
      AddDealer: "AddDealer",
      fetchAllAccountManagers: "fetchAllAccountManagers",
      AddAccountManager: "AddAccountManager",
      EditAccountManager: "EditAccountManager",
      EditDealer: "EditDealer",
      EditCustomer: "EditCustomer",
      EditSkrettingTechnicians: "EditSkrettingTechnicians"
    }),

    ...mapActions("superadmin/salesManager", {
      AddSalesManager: "AddSalesManager",
      EditSalesManager: "EditSalesManager",
      AddSalesExecutive: "AddSalesExecutive",
      EditSalesExecutive: "EditSalesExecutive"
    }),
    handleChangeInStatus(data) {
        this.user.status = data
    },
    ehm__error409Handler: function(err) {
      // let errorInnerHtml = ''
      if (err.response.data.errors != null) {
        const errorDetails = err.response.data.errors.details;
        errorDetails.forEach((el, index) => {
          // errorInnerHtml += '<li>' + el.message + '</li>'
          this.ehm__unhandledErrorMessage +=
            (index === 0 ? "" : ",") +
            el.message +
            (errorDetails.length - 1 === index ? "" : ",");
        });
      } else {
        err.response.data.key = "email";
        this.ehm__errMessagesObject.email = err.response.data;
      }
    },
    ehm__error403Handler: function(error) {
      this.ehm__errMessagesObject.email = {
        key: "email",
        ...error.response.data
      };
      this.ehm__errMessagesObject = Object.assign(
        {},
        this.ehm__errMessagesObject
      );
    },
    resetForm() {
      this.ehm__errMessagesObject = new User();
      this.user =
        this.action === "ADD" ? new User() : this.$lodash.cloneDeep(this.model);
    },
    handleDialogClose(message) {
      this.$emit("close", message);
      this.user_type = 'user';
      this.isAccountManagerAccesschecked = false;
    },
    async handleCountryChange(selectedCountry) {
      try {
        await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
        this.timezones = this.getTimeZones;
        this.user.timezone = this.timezones[Object.keys(this.getTimeZones)[0]];
      } catch (err) {
        console.error(err);
      }
    },
    async submitForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        if (this.user.country && this.user.country.name !== "") {
          await this.handleCountryChange(this.user.country);
        }
        this.user.first_name = this.upm__trimSpacesInStr(this.user.first_name);
        this.user.last_name = this.upm__trimSpacesInStr(this.user.last_name);
        this.user.email = this.upm__trimSpacesInStr(this.user.email || '' );
        this.user.mobile = this.upm__trimSpacesInStr(this.user.mobile || '');
        this.user.created_by = localStore.getItem(`${localStore.getItem("curr-user-type")}-id`)
        if (this.userType === 'SALES_MANAGER' && this.action !== 'EDIT') {
          this.user.allowed_roles = this.isAccountManagerAccesschecked ? ["SALES_MANAGER", "ACCOUNT_MANAGER"] : ["SALES_MANAGER"];
        } else if (this.userType === 'SALES_EXECUTIVE' && this.action !== 'EDIT') {
          this.user.allowed_roles = this.isAccountManagerAccesschecked ? ["SALES_EXECUTIVE", "ACCOUNT_MANAGER"] : ["SALES_EXECUTIVE"];
        }
        this.ehm__errMessagesObject = new User();
        const actionToCall = {
          DEALER_ADD: this.AddDealer,
          DEALER_EDIT: this.EditDealer,
          CUSTOMER_ADD: this.signUp,
          CUSTOMER_EDIT: this.EditCustomer,
          ACCOUNTMANAGER_ADD: this.AddAccountManager,
          ACCOUNTMANAGER_EDIT: this.EditAccountManager,
          SALES_MANAGER_ADD: this.AddSalesManager,
          SALES_MANAGER_EDIT: this.EditSalesManager,
          SALES_EXECUTIVE_ADD: this.AddSalesExecutive,
          SALES_EXECUTIVE_EDIT: this.EditSalesExecutive,
          SKRETTING_TECHNICIAN_EDIT: this.EditSkrettingTechnicians
        };
        let response;
        let payload;
        switch (this.userType) {
          case "CUSTOMER":
            payload = ["_id", "email", "first_name", "last_name", "mobile", "country", "tags", "allowed_account_managers", "created_by"].reduce((acc, field) => {
              if (field === "email" && this.user.country.name && !this.isIndianCustomer) {
                acc[field] = this.user[field]
                return acc
              }
              if (this.user[field] === "") {
                return acc;
              }
              acc[field] = this.user[field]
              return acc
            }, {})
            response = await actionToCall[`${this.userType}_${this.action}`](payload);
            break;
          default:
            response = await actionToCall[`${this.userType}_${this.action}`](
              this.user
            );
            break;
        }
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: response.message,
          duration: 10000,
          type: "success"
        });
        this.handleDialogClose("user_created");
      } catch (err) {
        if (err.response.status !== 422) {
            this.$notify({
            title: "Failed",
            message: err.response.data.message,
            duration: 10000,
            type: "error"
          });
        }
        this.ehm__errorMessages(err, false);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.create-new-customer-sa {
  .el-form {
    display: grid;
  }
  .el-form-item.el-form-item--mini {
    display: grid;
    grid-template-columns: 1fr 2.4fr;
    grid-template-rows: 1fr;
    .el-select__tags-text {
       white-space: nowrap;
     }
    .el-form-item__label {
      grid-area: 1 / 1 / 2 / 2;
    }
    .el-form-item__content {
      grid-area: 1 / 2 / 2 / 3;
      .el-input-number {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
    }
  }
}

.country-code-popper {
  width: 10% !important;
  margin-top: 5px;
  li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    line-height: 1;
    .country {
      width: 30%;
      margin: 0px 10px;
      right: 0%;
      text-align: left;
    }
    .country-code {
      width: 70%;
      margin: 0px 10px;
      left: 0%;
    }
  }
  .popper__arrow {
    left: 12% !important;
  }
}
.create_new_customer_dialog {
  border-radius: 10px;
}
</style>
