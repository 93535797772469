<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: accountManagerTableSA.vue
Description: This file contains the list of the Account Manager associated with the company and add new Account by the access of the superadmin
-->
<template>
  <layout :no-aside="true" class="account-table-sa">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Account Manager" name="account_manager">
        <div>
          <el-radio-group
            v-model="selectedStatus"
            size="mini"
            @input="handleStatusChange"
          >
            <el-radio-button label="ACTIVE" class="is-plain">
              {{ $t("Comn_active") }}
            </el-radio-button>
            <el-radio-button label="INACTIVE" class="is-plain">
              {{ $t("Comn_inactive") }}
            </el-radio-button>
            <el-radio-button label="ALL" class="is-plain">
              {{ $t("Comn_all") }}
            </el-radio-button>
          </el-radio-group>
          <create-new-customer
            user-type="ACCOUNTMANAGER"
            :model="formAccountManager"
            :action="actionToPerform"
            :visible="actionDialogVisible"
            @close="closeActionDialog"
          ></create-new-customer>

          <settings-table
            v-loading="loading"
            element-loading-background="white"
            :tableData="tableData"
            :columns="columnsObject"
            :total="total"
            :button="true"
            :tableProps="tableProps"
            :addPadingToSearch="true"
            :columnselector_require="false"
            placeHolderForSearch="Search_username"
            search-property="user_name"
            @cell-click="handleCellClick"
            @loadData="handleLoadData"
            @reload="handleLoadData"
            @cleared="handleLoadData"
            @add-item-click="handleAddClientClick"
          >
            <template slot="add-buttton">Add Account Manager</template>

            <template v-slot:name="{ row }">
              {{ row.data.first_name }} {{ row.data.last_name }}
            </template>
            <template v-slot:status="{ row }">
              {{ row.data.status === 'ACTIVE' ? "Active" : "Inactive" }}
            </template>

            <template v-slot:actions="{ row }">
              <el-button-group>
                <er-button size="mini" @click="handleEditClientClick(row.data)">
                  <span class="material-icons-round"> edit </span>
                </er-button>
              </el-button-group>
            </template>
          </settings-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Skretting Technician" name="skretting_technician">
        <div>
          <el-radio-group
            v-model="selectedSkrtStatus"
            size="mini"
            @input="handleSkrtStatusChange"
          >
            <el-radio-button label="ACTIVE" class="is-plain">
              {{ $t("Comn_active") }}
            </el-radio-button>
            <el-radio-button label="INACTIVE" class="is-plain">
              {{ $t("Comn_inactive") }}
            </el-radio-button>
            <el-radio-button label="ALL" class="is-plain">
              {{ $t("Comn_all") }}
            </el-radio-button>
          </el-radio-group>
          <create-new-customer
            user-type="SKRETTING_TECHNICIAN"
            :model="formAccountManager"
            :action="actionToPerform"
            :visible="actionDialogVisible"
            @close="skrtCloseActionDialog"
          ></create-new-customer>
          <settings-table
            v-loading="loading"
            element-loading-background="white"
            :tableData="skrtTableData"
            :columns="skrettingColumnsObject"
            :total="skrtTotal"
            :button="false"
            :tableProps="tableProps"
            :addPadingToSearch="true"
            :columnselector_require="false"
            placeHolderForSearch="Search_username"
            search-property="user_name"
            @loadData="handleLoadSkrtData"
            @reload="handleLoadSkrtData"
            @cleared="handleLoadSkrtData"
          >
            <template slot="add-buttton">Add Account Manager</template>

            <template v-slot:name="{ row }">
              {{ row.data.first_name }} {{ row.data.last_name }}
            </template>
            <template v-slot:customers_count="{ row }">
              {{ row.data.allowed_customers.length }}
            </template>
            <template v-slot:status="{ row }">
              {{ row.data.status === 'ACTIVE' ? "Active" : "Inactive" }}
            </template>

            <template v-slot:actions="{ row }">
              <el-button-group>
                <er-button size="mini" @click="handleEditSkrtUserClick(row.data)">
                  <span class="material-icons-round"> edit </span>
                </er-button>
              </el-button-group>
            </template>
          </settings-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import createNewCustomer from "@/components/superadmin/shared/createNewCustomer";
import layout from "@/components/superadmin/shared/layout";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import User from "@/model/user.js";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      tableData: [],
      skrtTableData: [],
      formAccountManager: new User(),
      countries: {},
      user: {},
      formName: "signUpForm",
      countryCode: "",
      loading: false,
      ehm__errMessagesObject: new User(),
      hasError: false,
      total: 0,
      skrtTotal: 0,
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      actionDialogVisible: false,
      dialogVisible: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: { status: "ACTIVE" },
      skrtQueryParams: { status: "ACTIVE" },
      newClient: {},
      usersList: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name", "email"],
        un_assigned_only: true
      },
      actionToPerform: "ADD",
      tableProps: {
        size: "small",
        height: "calc(100vh - 210px)",
        defaultSort: {
          prop: "first_name",
          order: "ascending"
        }
      },
      activeTab: "account_manager",
      selectedStatus: "ACTIVE",
      selectedSkrtStatus: "ACTIVE"
    };
  },
  components: {
    settingsTable,
    layout,
    createNewCustomer
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getCountries: "getCountries"
    }),
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    },
    columnsObject: function () {
      return [
        {
          label: "Name",
          prop: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "email",
          label: "Email Address",
          required: true,
          sortable: true
        },
        {
          prop: "country.name",
          label: "Country",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Mobile Number",
          required: true,
          sortable: true
        },
        {
          prop: "status",
          label: "Status",
          required: true,
          sortable: true
        },
        {
          label: "Actions",
          required: true
        }
      ];
    },
    skrettingColumnsObject: function () {
      return [
        {
          label: "Name",
          prop: "first_name",
          required: true,
          sortable: true
        },
        {
          prop: "email",
          label: "Email Address",
          required: true,
          sortable: true
        },
        {
          label: "Account Manager",
          prop: "created_by.full_name",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Mobile Number",
          required: true,
          sortable: true
        },
        {
          prop: "allowed_customers",
          label: "Customers Count",
          required: true,
        },
        {
          prop: "status",
          label: "Status",
          required: true,
          sortable: true
        },
        {
          label: "Actions",
          required: true
        }
      ];
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllAccountManagers: "fetchAllAccountManagers",
      fetchAllSkrettingUsers: "fetchAllSkrettingUsers",
      fetchCountryList: "fetchCountryList"
    }),
    getNewClient() {
      return {
        title: "",
        users: []
      };
    },
    handleStatusChange() {
      if (this.selectedStatus === "ALL") {
        delete this.queryParams.status
        this.handleLoadData(this.queryParams);
      } else {
        this.queryParams.status = this.selectedStatus;
        this.handleLoadData(this.queryParams);
      }
    },
    handleSkrtStatusChange() {
      if (this.selectedSkrtStatus === "ALL") {
        delete this.skrtQueryParams.status
        this.handleLoadSkrtData(this.skrtQueryParams);
      } else {
        this.skrtQueryParams.status = this.selectedSkrtStatus;
        this.handleLoadSkrtData(this.skrtQueryParams);
      }
    },
    showActionDialog(value) {
      this.actionDialogVisible = value;
    },
    async closeActionDialog(message) {
      this.showActionDialog(false);
      if (message === "user_created") {
        await this.handleLoadData(this.queryParams);
      }
    },
    async skrtCloseActionDialog(message) {
      this.showActionDialog(false);
      if (message === "user_created") {
        await this.handleLoadSkrtData(this.skrtQueryParams);
      }
    },
    handleTabClick(tab) {},
    async handleSelectedComponent(component) {
      this.tabName = component;
      // await this.handleLoadData(this.queryParams);
    },
    // handleAddUsersToClient(clientDetails) {
    //   this.action = "ADD_CLIENT_USERS";
    //   this.clientActionDialogTitle = "Add Client Users";
    //   this.actionDialogVisible = true;
    // },

    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = { ...this.queryParams, ...query };
        const response = await this.fetchAllAccountManagers({
          infinite_scroll: false,
          params: this.queryParams
        });
        // if (this.selectedStatus === "ALL") {
          this.tableData = this.$store.getters["superadmin/getAccountManagers"];
        // } else {
        //   this.tableData = this.$store.getters["superadmin/getAccountManagers"]
        //     .filter((x) => x.status === this.selectedStatus);
        // }
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    async handleLoadSkrtData(query) {
      try {
        this.loading = true;
        this.skrtQueryParams = { ...this.skrtQueryParams, ...query };
        const response = await this.fetchAllSkrettingUsers({
          infinite_scroll: false,
          params: this.skrtQueryParams
        });
        this.skrtTableData = this.$store.getters["superadmin/getskrtUserData"];
        this.skrtTotal = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        this.$router.push({
          name: "accountmanagerdetails",
          params: { accountmanager_id: row._id }
        });
      }
    },
    handleAddClientClick() {
      this.actionToPerform = "ADD";
      this.formAccountManager = new User();
      this.clientActionDialogTitle = "Add Account Manager";
      this.selectedClient = this.getNewClient();
      this.actionDialogVisible = true;
    },
    handleEditClientClick(acManagerDetails) {
      this.actionToPerform = "EDIT";
      this.formAccountManager = acManagerDetails;
      this.clientActionDialogTitle = "Edit Account Manager";
      this.actionDialogVisible = true;
    },
    handleEditSkrtUserClick(skrtUserDetails) {
      this.actionToPerform = "EDIT";
      this.formAccountManager = skrtUserDetails;
      this.clientActionDialogTitle = "Edit Skretting Technician";
      this.actionDialogVisible = true;
    },
    closeAddClientDialog() {
      this.actionDialogVisible = false;
      this.action = "";
    },
    handleAddClientDialogOpen() {
      delete this.userQuery.user_name;
      this.usersList = [];
      // this.$nextTick(() => {
      //   this.$refs.dialogListForm.reset();
      // });
    }
  }
  //   async initComponent() {
  //     await this.fetchCountryList();
  //   }
  // },

  // async mounted() {
  //   await this.initComponent();
  // }
};
</script>

<style lang="scss">
.account-table-sa {
  .material-icons-round {
    font-size: 14px;
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 84vh, 87vh);
  }
}
</style>
