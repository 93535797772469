<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: clientsViewSA.vue
Description: This page has components related to displaying the all clients data, search clients available, and creation of new clients
-->
<template>
  <layout :no-aside="true" class="client-table-sa">
    <div>
      <er-dialog
        :title="clientActionDialogTitle"
        :visible.sync="clientActionDialogVisible"
        :close-on-click-modal="false"
        :append-to-body="true"
        width="30%"
        @open="handleAddClientDialogOpen"
        custom-class="clientAction_dialog"
      >
        <ValidationObserver ref="dialogListForm">
          <el-form size="small" :model="selectedClient">
            <ValidationProvider
              name="Client Name"
              rules="required"
              v-slot="{ errors }"
            >
              <el-form-item label="Client Name" :error="errors[0]">
                <el-input
                  v-model="selectedClient.title"
                  :disabled="
                    ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(action)
                  "
                ></el-input>
              </el-form-item>
            </ValidationProvider>
            <ValidationProvider
              name="Users"
              rules="required"
              v-if="
                ['ADD_CLIENT_USERS', 'DELETE_CLIENT_USERS'].includes(action)
              "
              v-slot="{ errors }"
            >
              <el-form-item label="Users" :error="errors[0]">
                <er-select
                  v-model="selectedClient.users"
                  :loading="userListLoading"
                  multiple
                  filterable
                  collapse-tags
                  remote
                  :remote-method="fetchUsersToAssign"
                  value-key="_id"
                >
                  <el-option
                    v-for="item in usersList"
                    :key="item._id"
                    :label="item.first_name"
                    :value="item"
                  >
                    {{ item.last_name }} {{ item.first_name }} ({{
                      item.email
                    }})
                  </el-option>
                </er-select>
              </el-form-item>
            </ValidationProvider>
          </el-form>
        </ValidationObserver>
        <template slot="footer">
          <el-row type="flex" justify="end" align="middle">
            <er-button
              btnType="save"
              :showLabel="true"
              :showIcon="true"
              :loading="saveLoading"
              @click="handleSaveClient"
            ></er-button>
            <er-button
              btnType="cancel"
              :showLabel="true"
              :loading="saveLoading"
              @click="closeAddClientDialog"
            ></er-button>
          </el-row>
        </template>
      </er-dialog>
      <settings-table
        v-loading="loading"
        element-loading-background="white"
        :tableData="tableData"
        :columns="columnsObject"
        :tableProps="elTableProps"
        :total="total"
        :button="true"
        :columnselector_require="false"
        placeHolderForSearch="Search_username"
        search-property="user_name"
        :addPadingToSearch="true"
        @cell-click="handleCellClick"
        @loadData="handleLoadData"
        @reload="handleLoadData"
        @cleared="handleLoadData"
        @add-item-click="handleAddClientClick"
      >
        <template slot="add-buttton">Add Client</template>

        <template v-slot:actions="{ row }">
          <el-button-group>
            <er-button size="mini" @click="handleEditClientClick(row.data)">
              <span class="material-icons-round"> edit </span>
            </er-button>
            <er-button size="mini" @click="handleAddUsersToClient(row.data)">
              <span class="material-icons-round"> person_add </span>
            </er-button>
            <er-button
              size="mini"
              @click="handleDeleteUsersToClient(row.data)"
              :disabled="row.data.users.length === 0"
            >
              <span class="material-icons-round"> person_remove </span>
            </er-button>
          </el-button-group>
        </template>
      </settings-table>
    </div>
  </layout>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import settingsTable from "@/components/base/settingsTable";
import layout from "@/components/superadmin/shared/layout";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      tableData: [],
      total: 0,
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      clientActionDialogVisible: false,
      dialogVisible: false,
      loading: false,
      selectedClient: {},
      action: "ADD_CLIENT",
      queryParams: {},
      newClient: {},
      usersList: [],
      userListLoading: false,
      userQuery: {
        include: ["first_name", "last_name", "email"],
        un_assigned_only: true
      }
    };
  },
  components: {
    settingsTable,
    layout
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers"
    }),
    columnsObject: function () {
      return [
        {
          label: "Name",
          prop: "title",
          required: true,
          sortable: true
        },
        {
          prop: "phone",
          label: "Mobile Number",
          required: false,
          sortable: true
        },

        {
          label: "Actions",
          required: true
        }
      ];
    },
    elTableProps() {
      return {
        height: "calc(100vh - 150px)",
      };
    },
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAllClients: "fetchAllClients",
      fetchAllUsers: "fetchAllUsers",
      addUsersToClient: "addUsersToClient",
      deleteUsersToClient: "deleteUsersToClient",
      createClient: "createClient",
      updateClient: "updateClient",
      clearClientDetails: "clearClientDetails"
    }),
    getNewClient() {
      return {
        title: "",
        users: []
      };
    },
    handleTabClick(tab) {},
    async handleSelectedComponent(component) {
      this.tabName = component;
      // await this.handleLoadData(this.queryParams);
    },
    handleAddUsersToClient(clientDetails) {
      this.action = "ADD_CLIENT_USERS";
      this.clientActionDialogTitle = "Add Client Users";
      const newClient = this.getNewClient();
      newClient.title = clientDetails.title;
      newClient._id = clientDetails._id;
      this.selectedClient = newClient;
      this.clientActionDialogVisible = true;
      this.searchMethod = this.fetchUsersToAssign;
    },
    handleDeleteUsersToClient(clientDetails) {
      this.action = "DELETE_CLIENT_USERS";
      this.clientActionDialogTitle = "Delete Client Users";
      const newClient = this.getNewClient();
      newClient.title = clientDetails.title;
      newClient._id = clientDetails._id;
      this.selectedClient = newClient;
      this.clientActionDialogVisible = true;
      this.searchMethod = this.fetchUsersToAssign;
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAllClients({
          infinite_scroll: false,
          params: query
        });
        this.tableData = this.$store.getters["superadmin/getClients"];
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    handleCellClick(row, column, cell, event) {
      if (column.label === this.columnsObject[0].label) {
        // this.clearUserData();
        this.clearClientDetails();
        this.$router.push({
          name: "clientDetails",
          params: { client_id: row._id }
        });
      }
    },
    handleAddClientClick() {
      this.action = "ADD_CLIENT";
      this.clientActionDialogTitle = "Add Client";
      this.selectedClient = this.getNewClient();
      this.clientActionDialogVisible = true;
    },
    handleEditClientClick(clientDetails) {
      this.action = "EDIT_CLIENT";
      this.clientActionDialogTitle = "Edit Client";
      this.selectedClient = this.$lodash.cloneDeep(clientDetails);
      this.clientActionDialogVisible = true;
    },
    closeAddClientDialog() {
      this.clientActionDialogVisible = false;
      this.action = "";
    },
    handleAddClientDialogOpen() {
      delete this.userQuery.user_name;
      this.usersList = [];
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },

    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.userListLoading = true;
        this.userQuery.user_name = queryString;

        if (["ADD_CLIENT_USERS"].includes(this.action)) {
          this.userQuery.excluded_client_ids = [this.selectedClient._id];
          delete this.userQuery.included_client_ids;
        } else if (["DELETE_CLIENT_USERS"].includes(this.action)) {
          this.userQuery.included_client_ids = [this.selectedClient._id];
          delete this.userQuery.excluded_client_ids;
        }
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: this.userQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        this.usersList = this.getUsers;
      }
    },
    async handleSaveClient() {
      const actionToMessage = {
        ADD_CLIENT: {
          success: `Created ${this.selectedClient.title} Successfully`,
          fail: `Failed to create ${this.selectedClient.title}`
        },
        EDIT_CLIENT: {
          success: `Updated ${this.selectedClient.title} Successfully`,
          fail: `Failed to update ${this.selectedClient.title}`
        },
        ADD_CLIENT_USERS: {
          success: `Added users to ${this.selectedClient.title} Successfully`,
          fail: `Failed to add users to ${this.selectedClient.title}`
        },
        DELETE_CLIENT_USERS: {
          success: `Deleted users from ${this.selectedClient.title} Successfully`,
          fail: `Failed to delete users from  ${this.selectedClient.title}`
        }
      };
      try {
        const response = await this.$refs.dialogListForm.validate();
        console.log(response);
        if (!response) return;
        this.saveLoading = true;
        const actionToStoreAction = {
          ADD_CLIENT: this.createClient,
          EDIT_CLIENT: this.updateClient,
          DELETE_CLIENT_USERS: this.deleteUsersToClient,
          ADD_CLIENT_USERS: this.addUsersToClient
        };
        let payload;
        if (["ADD_CLIENT_USERS", "DELETE_CLIENT_USERS"].includes(this.action)) {
          payload = {
            client_id: this.selectedClient._id,
            user_ids: this.selectedClient.users.map((x) => x._id)
          };
        } else if (this.action === "ADD_CLIENT") {
          payload = {
            title: this.selectedClient.title
          };
        } else {
          payload = {
            client_id: this.selectedClient._id,
            title: this.selectedClient.title
          };
        }

        await actionToStoreAction[this.action](payload);
        this.$notify({
          type: "success",
          title: "Success",
          message: actionToMessage[this.action].success
        });
        this.closeAddClientDialog();
        await this.handleLoadData(this.queryParams);
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Failed",
          message: actionToMessage[this.action].fail
        });
        this.ehm__errorMessages(err, true);
      } finally {
        this.saveLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.client-table-sa {
  .material-icons-round {
    font-size: 14px;
  }
  .settings-table .settings-table-scroll {
    position: relative;
    @include responsiveProperty(height, 80vh, 84vh, 87vh);
  }
}
.clientAction_dialog {
  background: #fcfcfc;
  border-radius: 10px;

  .el-input--small .el-input__inner {
    @include responsiveProperty(width, 205px, 227px, 250px);
  }

  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    @include responsiveProperty(min-width, 100px, 130px, 190px);
  }
}
</style>
