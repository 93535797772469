<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AddAccountCustomers.vue
Description:This file contains the form  where the details of the customers  filled and associated with account manager  by superadmin
-->
<template>
  <div class="add-AccountCustomers">
    <er-dialog
      width="30%"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      @open="handleOpenDialog"
      :title="dailogTitle"
      class="action-on-dealer"
      custom-class="Add_Users_To_Account_Manager"
    >
      <ValidationObserver ref="dialogListForm">
        <el-form size="large">
          <ValidationProvider
            v-if="dailogTitle === 'Add User'"
            name="User Name"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item :error="errors[0]">
              <er-select
                :placeholder="$t('Comn_search')"
                :loading="userListLoading"
                class="select-devices"
                v-model="selectedUsers"
                value-key="_id"
                collapse-tags
                multiple
                filterable
                remote
                :remote-method="fetchUsersToAssign"
              >
                <el-option
                  v-for="item in usersList"
                  :key="item._id"
                  :label="
                    item.first_name +
                      '\xa0\xa0' +
                      item.last_name.concat('(' + item.email + ')')
                  "
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
          <ValidationProvider
            v-else
            name="Skretting Technician"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item :error="errors[0]">
              <er-select
                :placeholder="'Search'"
                :loading="userListLoading"
                class="select-devices"
                v-model="selectedSkrtUsers"
                value-key="_id"
                collapse-tags
                multiple
                filterable
                remote
                :remote-method="fetchSkrtUsersToAssign"
              >
                <el-option
                  v-for="item in skrtUsersList"
                  :key="item._id"
                  :label="
                    item.first_name +
                      '\xa0\xa0' +
                      item.last_name.concat('(' + item.email + ')')
                  "
                  :value="item"
                ></el-option>
              </er-select>
            </el-form-item>
          </ValidationProvider>
        </el-form>
      </ValidationObserver>

      <template slot="footer">
        <er-button
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="saveLoading"
          @click="submitUserDetails"
        ></er-button>
        <er-button
          btnType="cancel"
          :showLabel="true"
          :loading="saveLoading"
          @click="handleCloseDialog"
        ></er-button>
      </template>
    </er-dialog>

    <el-tabs v-model="activeTab">
      <el-tab-pane label="Users" name="users">
        <settings-table
          v-loading="loading"
          element-loading-background="white"
          :tableData="tableData"
          :columns="columnsObject"
          :total="total"
          :button="true"
          :key="accountmanager_id"
          class="add_PondMother"
          placeHolderForSearch="Search_username"
          :columnselector_require="false"
          search-property="user_name"
          :addPadingToSearch="true"
          @loadData="handleLoadData"
          @reload="handleLoadData"
          @cleared="handleLoadData"
          @add-item-click="handleAddDealerClick"
        >
          <template slot="add-buttton">Add User</template>

          <template v-slot:name="{ row }">
            {{ row.data.first_name }} {{ row.data.last_name }}
          </template>

          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button size="mini" @click="handleUserDelete(row.data)">
                <span class="material-icons-round"> delete </span>
              </er-button>
            </el-button-group>
          </template>
        </settings-table>
      </el-tab-pane>
      <el-tab-pane label="Skretting Technician" name="skretting_technician">
        <settings-table
          v-loading="loading"
          element-loading-background="white"
          :tableData="skrtTableDataValues"
          :columns="skrettingColumnsObject"
          :total="skrtTotal"
          :button="true"
          :key="accountmanager_id"
          placeHolderForSearch="Search_username"
          :columnselector_require="false"
          search-property="user_name"
          :addPadingToSearch="true"
          @loadData="handleLoadSkrtData"
          @reload="handleLoadSkrtData"
          @cleared="handleLoadSkrtData"
          @add-item-click="handleAddSkrtClick"
        >
          <template slot="add-buttton">Add Skretting Technician</template>

          <template v-slot:name="{ row }">
            {{ row.data.first_name }} {{ row.data.last_name }}
          </template>

          <template v-slot:actions="{ row }">
            <el-button-group>
              <er-button size="mini" @click="handleUserDelete(row.data)">
                <span class="material-icons-round"> delete </span>
              </er-button>
            </el-button-group>
          </template>
        </settings-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import settingsTable from "@/components/base/settingsTable";
import { mapActions, mapGetters } from "vuex";
import notificationMixin from "@/mixins/notificationMixin";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "{_field_} is required"
});
export default {
  mixins: [errorHandlerMixin, errorKeyMapMixin, notificationMixin],
  props: ["accountmanager_id"],
  data: function() {
    return {
      tableData: [],
      skrtTableData: [],
      selectedUsers: [],
      selectedSkrtUsers: [],
      total: 0,
      skrtTotal: 0,
      usersList: [],
      skrtUsersList: [],
      clientActionDialogTitle: "Add",
      dialogBoxLoading: false,
      saveLoading: false,
      dialogVisible: false,
      dailogTitle: 'Add User',
      userListLoading: false,
      loading: false,
      selectedClient: {},
      userSearchQuery: {},
      action: "ADD_CLIENT",
      queryParams: {},
      userQuery: {
        include: ["first_name", "last_name"],
        un_assigned_only: true
      },
      actionToPerform: {
        default: ""
      },
      activeTab: "users"
    };
  },
  components: {
    settingsTable
  },
  computed: {
    ...mapGetters("superadmin", {
      getUsers: "getUsers",
      getFilteredSkrtUserData: "getFilteredSkrtUserData"
    }),
    columnsObject: function() {
      return [
        {
          label: "Email Address",
          prop: "email",
          required: true,
          sortable: true
        },

        {
          prop: "first_name",
          label: "Name",
          required: true,
          sortable: true
        },

        {
          prop: "phone",
          label: "Mobile Number",
          required: true,
          sortable: true
        },
        {
          prop: "country.name",
          label: "Country",
          required: true,
          sortable: true
        },
        {
          label: "Actions",
          required: true
        }
      ];
    },
    skrettingColumnsObject: function() {
      return [
        {
          label: "Email Address",
          prop: "email",
          required: true,
          sortable: true
        },

        {
          prop: "full_name",
          label: "Name",
          required: true,
          sortable: true
        },
        // {
        //   prop: "first_name",
        //   label: "Account Manager",
        //   required: true,
        //   sortable: true
        // },
        {
          prop: "phone",
          label: "Mobile Number",
          required: true,
          sortable: true
        },
        // {
        //   prop: "country.name",
        //   label: "Country",
        //   required: true,
        //   sortable: true
        // },
        {
          label: "Actions",
          required: true
        }
      ];
    },
    skrtTableDataValues() {
      return this.skrtTableData;
    }
  },
  methods: {
    ...mapActions("superadmin", {
      fetchAlreadyAssingedAccountUsers: "fetchAlreadyAssingedAccountUsers",
      fetchAllUsers: "fetchAllUsers",
      addUserToAccountManger: "addUserToAccountManger",
      addSkrtUserToAccountManger: "addSkrtUserToAccountManger",
      removeAssignedUsersFromAccountManager:
        "removeAssignedUsersFromAccountManager",
        removeAssignedSkrtUsersFromAccountManager: "removeAssignedSkrtUsersFromAccountManager",
        fetchAllFilteredSkrettingUsers: "fetchAllFilteredSkrettingUsers",
      fetchAlreadyAssingedAccountSkrtUsers: "fetchAlreadyAssingedAccountSkrtUsers"
    }),
    async fetchUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.userListLoading = true;
        this.userSearchQuery.user_name = queryString;
        this.userSearchQuery.type = "ADMIN";
        this.userSearchQuery.email_not_verified_users_only = false;
        await this.fetchAllUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        const myArrayFiltered = this.getUsers.filter(
          entry1 => !this.tableData.some(entry2 => entry1._id === entry2._id)
        );
        this.usersList = myArrayFiltered;
      }
    },
    async fetchSkrtUsersToAssign(queryString) {
      if (queryString === "") return;
      try {
        this.userListLoading = true;
        this.userSearchQuery.user_name = queryString;
        this.userSearchQuery.email_not_verified_users_only = false;
        this.userSearchQuery.status = 'INACTIVE';
        await this.fetchAllFilteredSkrettingUsers({
          infinite_scroll: false,
          params: this.userSearchQuery
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.userListLoading = false;
        const myArrayFiltered = this.getFilteredSkrtUserData.filter(
          entry1 => !this.skrtTableData.some(entry2 => entry1._id === entry2._id)
        );
        this.skrtUsersList = myArrayFiltered;
      }
    },
    getNewClient() {
      return {
        title: undefined,
        users: []
      };
    },
    async handleUserDelete(removeuserDetails) {
      try {
        let message = '';
       if (this.activeTab === 'users') {
          const payload = {
            account_manager_id: this.accountmanager_id,
            user_ids: [removeuserDetails._id]
          };
          const confirm_message = removeuserDetails.first_name;
          await this.nofm__deleteConfirmation(confirm_message, "table");
          message = "User deleted Successfully";
          this.loading = true;
          await this.removeAssignedUsersFromAccountManager(payload);
       } else {
          const payload = {
            status: "INACTIVE",
            skrt_id: removeuserDetails._id
          };
          const confirm_message = removeuserDetails.first_name + ' ' + removeuserDetails.last_name;
          await this.nofm__deleteConfirmation(confirm_message, "table");
          message = "Skretting Technician deleted Successfully";
          this.loading = true;
          await this.removeAssignedSkrtUsersFromAccountManager(payload);
       }
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: message,
          duration: 5000,
          type: "success"
        });
      } catch (err) {
        const errors = [];
        if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });

          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;

        if (this.activeTab === 'users') {
          await this.handleLoadData(this.queryParams);
        } else {
          await this.handleLoadSkrtData(this.queryParams);
        }
        // this.dealer_Users();
      }
    },
    async handleLoadData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAlreadyAssingedAccountUsers({
          accountmanager_id: this.accountmanager_id,
          infinite_scroll: false,
          params: query
        });
        this.tableData = response.users;
        this.total = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },
    async handleLoadSkrtData(query) {
      try {
        this.loading = true;
        this.queryParams = query;
        const response = await this.fetchAlreadyAssingedAccountSkrtUsers({
          accountmanager_id: this.accountmanager_id,
          infinite_scroll: false,
          params: { ...query, status: 'ACTIVE' }
        });
        this.skrtTableData = response.users;
        this.skrtTotal = response.total;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.loading = false;
      }
    },

    handleAddDealerClick() {
      this.dailogTitle = 'Add User'
      this.dialogVisible = true;
    },
    handleAddSkrtClick() {
      this.dailogTitle = 'Add Skretting Technician';
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    },
    handleOpenDialog(event) {
      this.usersList = [];
      this.skrtUsersList = [];
      this.selectedUsers = "";
      this.selectedSkrtUsers = [];
      this.$nextTick(() => {
        this.$refs.dialogListForm.reset();
      });
    },
    async submitUserDetails() {
      const response = await this.$refs.dialogListForm.validate();
      if (!response) return;
      try {
        this.saveLoading = true;
        if (this.dailogTitle === 'Add User') {
          const payload = {
            account_manager_id: this.accountmanager_id,
            user_ids: this.selectedUsers.map(x => x._id)
          };
          await this.addUserToAccountManger(payload);
        } else {
          const payload = {
            account_manager_id: this.accountmanager_id,
            skretting_technician_ids: this.selectedSkrtUsers.map(x => x._id)
          };
          await this.addSkrtUserToAccountManger(payload);
        }
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: "Added Successfully",
          duration: 5000,
          type: "success"
        });
      } catch (error) {
        this.ehm__errorMessages(error, true);
      } finally {
        this.dialogVisible = false;
        this.saveLoading = false;
        if (this.dailogTitle === 'Add User') {
          await this.handleLoadData(this.queryParams);
        } else {
          await this.handleLoadSkrtData(this.queryParams);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.add-AccountCustomers {
  .client-table-sa {
    .material-icons-round {
      font-size: 14px;
    }
  }
  .el-table__body-wrapper {
    @include responsiveProperty(height, 400px, 500px, 600px);
    overflow-y: scroll;
  }
  .settings-table .settings-table-scroll {
    position: relative;
    // @include responsiveProperty(height, 75vh, 79vh, 85vh);
    @include responsiveProperty(height, 83vh, 85vh, 88vh);
  }
  .settings-table .padding-10-0 {
    padding: 10px 10px;
  }
}
.Add_Users_To_Account_Manager {
  border-radius: 10px;
}
</style>
